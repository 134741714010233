/* eslint-disable @typescript-eslint/restrict-template-expressions */
// Import the styles
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { ThumbnailIcon } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
// Import styles of default layout plugin
import { Grid, Typography } from '@mui/material';
import { Viewer } from '@react-pdf-viewer/core';
// default layout plugin
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
// import Loader from 'components/Loader';
import React, { useEffect, useRef, useState } from 'react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
interface pdfProps {
  fileUrl: any;
  jumpIndex?: any;
  updateSearchPage?: any;
}
export const PDFViewer: React.FC<any> = (pdfProps: pdfProps) => {
  const viewerRef = useRef<any>(null);
  const { fileUrl, jumpIndex, updateSearchPage } = pdfProps;
  const [sidePanalOpen, setSidePanalOpen] = useState<boolean>(false);
  const toolbarPluginInstance = toolbarPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;
  const { Toolbar } = toolbarPluginInstance;
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const renderToolBar = (props) => {
    const {
      CurrentPageInput,
      Download,
      GoToNextPage,
      GoToPreviousPage,
      NumberOfPages,
      Print,
      Zoom,
      ZoomIn,
      ZoomOut,
      // pageScroll,
    } = props;

    // const pageScrollTrack = (data) => {
    //   // setPageScroll(data?.currentPage + 1);
    //   updateSearchPage(data?.currentPage + 1);
    // };

    return (
      <>
        <Grid md={12} sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid md={3} sx={{ display: 'flex', marginLeft: '20px' }}>
            <MenuOpenIcon className='pointer' onClick={() => setSidePanalOpen(!sidePanalOpen)} />
          </Grid>

          <Grid md={2}>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </Typography>
          </Grid>

          <Grid md={5}>
            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <GoToPreviousPage />
              <GoToNextPage />
              <CurrentPageInput />
              / <NumberOfPages />
            </Typography>
          </Grid>
          <Grid md={2}>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <Download />
              <Print />
              {/* <div style={{ display: 'none' }}>
                <CurrentPageLabel>{(data) => <>{pageScrollTrack(data)}</>}</CurrentPageLabel>
              </div> */}
            </Typography>
          </Grid>
        </Grid>
        {/* <Typography
          component={'div'}
          sx={{
            borderRight: '1px solid rgba(0, 0, 0, 0.3)',
            overflow: 'auto',
            width: '10%',
          }}>
          <Thumbnails />
        </Typography> */}
        {/* <Typography component={'div'} sx={{ padding: '0px 2px' }}>
          <ZoomOut />
        </Typography> */}
        {/* <Typography component={'div'} sx={{ padding: '0px 2px' }}>
          <Zoom />
        </Typography>
        <Typography component={'div'} sx={{ padding: '0px 2px' }}>
          <ZoomIn />
        </Typography> */}
        {/* <Typography component={'div'} sx={{ marginLeft: 'auto', padding: '0px 2px' }}>
          <GoToPreviousPage />
        </Typography> */}
        {/* <Typography component={'div'} sx={{ padding: '0px 2px', width: '4rem' }}>
          <CurrentPageInput />
        </Typography>
        <Typography component={'div'} sx={{ padding: '0px 2px' }}>
          / <NumberOfPages />
        </Typography> */}
        {/* <Typography component={'div'} sx={{ padding: '0px 2px' }}>
          <GoToNextPage />
        </Typography> */}
        {/* <Typography component={'div'} sx={{ padding: '0px 2px' }}>
          <Download />
        </Typography>
        <Typography component={'div'} sx={{ padding: '0px 2px' }}>
          <Print />
        </Typography> */}
      </>
    );
  };
  const handlePageChange = (data) => {
    updateSearchPage(data?.currentPage + 1);
  };

  useEffect(() => {
    jumpToPage(jumpIndex - 1);
  }, [jumpIndex]);
  return (
    <Typography
      component={'div'}
      className='rpv-core__viewer'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '84vh',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03) !important',
      }}>
      <Typography
        component={'div'}
        sx={{
          alignItems: 'center',
          backgroundColor: '#e5ebfa',
          display: 'flex',
          padding: '4px',
          borderRadius: '12px 12px 0px 0px',
        }}>
        <Toolbar>
          {(props: ToolbarSlot) => {
            return renderToolBar(props);
          }}
        </Toolbar>
      </Typography>
      <div
        className='rpv-core__viewer'
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          height: '100%',
        }}>
        <div
          style={{
            borderRight: '1px solid rgba(0, 0, 0, 0.3)',
            overflow: 'auto',
            width: '20%',
            display: !sidePanalOpen ? 'none' : '',
          }}>
          <Thumbnails />
        </div>
        <Typography
          component={'div'}
          sx={{
            flex: 1,
            overflow: 'hidden',
            backgroundColor: '#e6e6e8 !important',
          }}>
          <Viewer
            fileUrl={fileUrl}
            defaultScale={1.2}
            ref={viewerRef}
            onPageChange={(e) => handlePageChange(e)}
            plugins={[toolbarPluginInstance, thumbnailPluginInstance, pageNavigationPluginInstance]}
            // renderLoader={(percentages: number) => <CircularProgress />}
          />
        </Typography>
      </div>
    </Typography>
  );
};

export default PDFViewer;
