import { TextField } from '@mui/material';
import React, { ReactElement } from 'react';

type InputProps = {
  autoComplete?: boolean;
  placeHolder?: string;
  fullWidth?: any;
  className?: string;
  onChange?: (src: any) => void;
  onBlur?: (src: any) => void;
  extraStyle?: any;
  value?: string;
  variant?: any;
  error?: any;
  name?: string;
  type?: string;
  inputProps?: any;
  id?: string;
  maxLength?: number;
  tabIndex?: number;
  isTextArea?: boolean;
  disabled?: boolean;
  minRows?: number;
  onFocus?: (src: any) => void;
  focus?: boolean;
  defaultValue?: string;
  size?: 'small' | 'medium' | undefined;
};

// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef((props: InputProps, ref: any): ReactElement => {
  const {
    placeHolder,
    className,
    onChange,
    onBlur,
    extraStyle,
    value,
    variant,
    error,
    name = '',
    type = 'text',
    inputProps = {},
    id = '',
    maxLength,
    isTextArea = false,
    autoComplete = false,
    disabled,
    minRows = 3,
    onFocus,
    focus,
    tabIndex,
    defaultValue = '',
    size = 'medium',
    fullWidth,
  } = props;

  return (
    <TextField
      defaultValue={defaultValue}
      {...(focus ? { autoFocus: true } : {})}
      placeholder={variant ? '' : placeHolder}
      fullWidth={fullWidth}
      label={variant ? placeHolder : ''}
      className={className}
      onChange={onChange && onChange}
      onBlur={onBlur && onBlur}
      onFocus={onFocus && onFocus}
      name={name}
      disabled={disabled}
      inputRef={ref}
      id={id}
      style={extraStyle}
      value={value}
      variant={variant}
      error={error}
      helperText={error}
      type={type}
      InputProps={{
        ...inputProps,
      }}
      inputProps={{
        autoComplete: autoComplete ? 'on' : 'off',
        maxLength: maxLength,
        tabIndex: tabIndex,
      }}
      multiline={isTextArea ? true : false}
      minRows={isTextArea ? (minRows ? minRows : 3) : 1}
      maxRows={isTextArea ? (minRows ? minRows : 3) : 1}
      {...(size ? { size: size } : {})}
    />
  );
});

export default CustomInput;
