import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
const middleware = [logger];

import counterReducer from 'store/reducers';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
  middleware,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
