import instance from 'services/apis';

interface PostRequestProps {
  url: string;
  data?: any;
  token?: string;
}

interface GetRequestProps {
  url: string;
  token?: string;
}
const url = 'https://indexingservices.itechlab.in';
const postRequest = async (props: PostRequestProps, options?: any) => {
  return await instance
    .post(url + props.url, props.data, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if (error.response) {
        throw error.response.data;
      }
    });
};
const putRequest = async (props: PostRequestProps) => {
  return await instance
    .put(url + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const deleteRequest = async (props: PostRequestProps) => {
  return await instance
    .delete(url + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const getRequest = async (props: GetRequestProps, options?: any) => {
  return await instance
    .get(
      url + props.url,
      props.token !== '' && {
        // headers: {
        //   Authorization: `Bearer ${props.token}`,
        // },
        ...options,
      }
    )
    .then((response: any) => {
      if (response.data) {
        return response.data;
      } else {
        return {
          data: response,
        };
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : '';
    });
};

const getPdfRequest = async (props: GetRequestProps, options?: any) => {
  return await instance
    .get(url + props.url, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : '';
    });
};

const getPublicRequest = async (endpoint: string, options?: any) => {
  return await instance
    .get(url + endpoint, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error && error.response && error.response.data
        ? error.response.data
        : '';
    });
};

export {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest,
  getPdfRequest,
  getPublicRequest,
};
