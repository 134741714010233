/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateItechIndexing_primary = /* GraphQL */ `
  subscription OnCreateItechIndexing_primary(
    $signed_email_id: String
    $created: Int
    $batched_result: String
    $priority: Int
  ) {
    onCreateItechIndexing_primary(
      signed_email_id: $signed_email_id
      created: $created
      batched_result: $batched_result
      priority: $priority
    ) {
      signed_email_id
      created
      attachment_number
      attachment_size
      batched_result
      batched_status
      clarify_requested_by
      client_code
      date_reconcile
      description
      display_task
      email
      email_body_pages
      email_raw_file_path
      file_log
      file_source
      from
      iscompleted_by
      isrejected_by
      location
      message_id
      ml_result
      ml_status
      pages
      priority
      scac
      source
      task_info
      task_lock
      task_lock_by
      task_status
      total_attachment
    }
  }
`;
export const onUpdateItechIndexing_primary = /* GraphQL */ `
  subscription OnUpdateItechIndexing_primary(
    $signed_email_id: String
    $created: Int
    $task_lock: Boolean
    $attachment_size: String
    $batched_result: String
  ) {
    onUpdateItechIndexing_primary(
      signed_email_id: $signed_email_id
      created: $created
      task_lock: $task_lock
      attachment_size: $attachment_size
      batched_result: $batched_result
    ) {
      signed_email_id
      created
      attachment_number
      attachment_size
      batched_result
      batched_status
      clarify_requested_by
      client_code
      date_reconcile
      description
      display_task
      email
      email_body_pages
      email_raw_file_path
      file_log
      file_source
      from
      iscompleted_by
      isrejected_by
      location
      message_id
      ml_result
      ml_status
      pages
      priority
      scac
      source
      task_info
      task_lock
      task_lock_by
      task_status
      total_attachment
    }
  }
`;
export const onDeleteItechIndexing_primary = /* GraphQL */ `
  subscription OnDeleteItechIndexing_primary(
    $signed_email_id: String
    $created: Int
    $attachment_number: String
    $attachment_size: String
    $batched_result: String
  ) {
    onDeleteItechIndexing_primary(
      signed_email_id: $signed_email_id
      created: $created
      attachment_number: $attachment_number
      attachment_size: $attachment_size
      batched_result: $batched_result
    ) {
      signed_email_id
      created
      attachment_number
      attachment_size
      batched_result
      batched_status
      clarify_requested_by
      client_code
      date_reconcile
      description
      display_task
      email
      email_body_pages
      email_raw_file_path
      file_log
      file_source
      from
      iscompleted_by
      isrejected_by
      location
      message_id
      ml_result
      ml_status
      pages
      priority
      scac
      source
      task_info
      task_lock
      task_lock_by
      task_status
      total_attachment
    }
  }
`;
export const onCreateIndexingDashboardPriority = /* GraphQL */ `
  subscription OnCreateIndexingDashboardPriority(
    $created: Int
    $signed_email_id: String
  ) {
    onCreateIndexingDashboardPriority(
      created: $created
      signed_email_id: $signed_email_id
    ) {
      signed_email_id
      created
      attachment_number
      attachment_size
      batched_result
      batched_status
      clarify_requested_by
      client_code
      date_reconcile
      description
      display_task
      email
      email_body_pages
      email_raw_file_path
      file_log
      file_source
      from
      iscompleted_by
      isrejected_by
      location
      message_id
      ml_result
      ml_status
      pages
      priority
      scac
      source
      task_info
      task_lock
      task_lock_by
      task_status
      total_attachment
    }
  }
`;
export const onUpdateIndexingDashboardPriority = /* GraphQL */ `
  subscription OnUpdateIndexingDashboardPriority(
    $created: Int
    $signed_email_id: String
    $task_lock: Boolean
  ) {
    onUpdateIndexingDashboardPriority(
      created: $created
      signed_email_id: $signed_email_id
      task_lock: $task_lock
    ) {
      signed_email_id
      created
      attachment_number
      attachment_size
      batched_result
      batched_status
      clarify_requested_by
      client_code
      date_reconcile
      description
      display_task
      email
      email_body_pages
      email_raw_file_path
      file_log
      file_source
      from
      iscompleted_by
      isrejected_by
      location
      message_id
      ml_result
      ml_status
      pages
      priority
      scac
      source
      task_info
      task_lock
      task_lock_by
      task_status
      total_attachment
    }
  }
`;
export const onDeleteIndexingDashboardPriority = /* GraphQL */ `
  subscription OnDeleteIndexingDashboardPriority(
    $created: Int
    $signed_email_id: String
  ) {
    onDeleteIndexingDashboardPriority(
      created: $created
      signed_email_id: $signed_email_id
    ) {
      signed_email_id
      created
      attachment_number
      attachment_size
      batched_result
      batched_status
      clarify_requested_by
      client_code
      date_reconcile
      description
      display_task
      email
      email_body_pages
      email_raw_file_path
      file_log
      file_source
      from
      iscompleted_by
      isrejected_by
      location
      message_id
      ml_result
      ml_status
      pages
      priority
      scac
      source
      task_info
      task_lock
      task_lock_by
      task_status
      total_attachment
    }
  }
`;
export const onCreateLogisticsClientInformation = /* GraphQL */ `
  subscription OnCreateLogisticsClientInformation($client_code: String) {
    onCreateLogisticsClientInformation(client_code: $client_code) {
      client_code
      account_name
      client_location
      processing_site_code
    }
  }
`;
export const onUpdateLogisticsClientInformation = /* GraphQL */ `
  subscription OnUpdateLogisticsClientInformation($client_code: String) {
    onUpdateLogisticsClientInformation(client_code: $client_code) {
      client_code
      account_name
      client_location
      processing_site_code
    }
  }
`;
export const onDeleteLogisticsClientInformation = /* GraphQL */ `
  subscription OnDeleteLogisticsClientInformation($client_code: String) {
    onDeleteLogisticsClientInformation(client_code: $client_code) {
      client_code
      account_name
      client_location
      processing_site_code
    }
  }
`;
export const onCreateScacCode = /* GraphQL */ `
  subscription OnCreateScacCode($unique_id: String) {
    onCreateScacCode(unique_id: $unique_id) {
      unique_id
      scac
    }
  }
`;
export const onUpdateScacCode = /* GraphQL */ `
  subscription OnUpdateScacCode($unique_id: String) {
    onUpdateScacCode(unique_id: $unique_id) {
      unique_id
      scac
    }
  }
`;
export const onDeleteScacCode = /* GraphQL */ `
  subscription OnDeleteScacCode($unique_id: String) {
    onDeleteScacCode(unique_id: $unique_id) {
      unique_id
      scac
    }
  }
`;
export const onCreateScacCarrierInformation = /* GraphQL */ `
  subscription OnCreateScacCarrierInformation(
    $SCAC: String
    $carrier_name: String
    $model_trained: String
  ) {
    onCreateScacCarrierInformation(
      SCAC: $SCAC
      carrier_name: $carrier_name
      model_trained: $model_trained
    ) {
      SCAC
      carrier_name
      model_trained
    }
  }
`;
export const onUpdateScacCarrierInformation = /* GraphQL */ `
  subscription OnUpdateScacCarrierInformation(
    $SCAC: String
    $carrier_name: String
    $model_trained: String
  ) {
    onUpdateScacCarrierInformation(
      SCAC: $SCAC
      carrier_name: $carrier_name
      model_trained: $model_trained
    ) {
      SCAC
      carrier_name
      model_trained
    }
  }
`;
export const onDeleteScacCarrierInformation = /* GraphQL */ `
  subscription OnDeleteScacCarrierInformation(
    $SCAC: String
    $carrier_name: String
    $model_trained: String
  ) {
    onDeleteScacCarrierInformation(
      SCAC: $SCAC
      carrier_name: $carrier_name
      model_trained: $model_trained
    ) {
      SCAC
      carrier_name
      model_trained
    }
  }
`;
