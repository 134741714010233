import React, { useState } from 'react';
import { Button, Typography, InputAdornment, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Passlock from 'assets/icon/passlock.svg';
import Itec from 'assets/icon/itec.svg';
import { Authenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginFormSchema } from 'utils/ValidatorSchema';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import indexLogo from '../../assets/images/index_logo_transparent_bg.png';

export const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors: loginErrors },
  } = useForm<{
    userName: string;
    password: string;
  }>({
    criteriaMode: 'all',
    defaultValues: { userName: '', password: '' },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginFormSchema),
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const submitLoginDatas = async (loginData: any) => {
    const loginPayload: any = {
      email: loginData.userName,
      password: loginData.password,
    };
    await axios
      .post('https://d2lauth.itechlab.in/ldap', loginPayload)
      .then((res) => {
        if (!_.isEmpty(res) && res?.data?.accessToken) {
          const { accessToken } = res.data;
          localStorage.setItem('accessToken', accessToken);
          navigate('/dashboard');
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  return (
    <Typography component={'div'} className='l-container d-flex-ja'>
      <Typography component={'div'} className='center-box'>
        <Typography component={'div'} className='top'>
          <Typography className='top-title'>
            <img style={{ height: 40 }} src={indexLogo} />
          </Typography>
          <form id='loginForm' onSubmit={handleSubmit(submitLoginDatas)}>
            <Controller
              control={control}
              defaultValue=''
              name='userName'
              render={({ field: { value, onChange, name } }) => {
                return (
                  <TextField
                    name={name}
                    className='w-100 mb-16'
                    id='input-with-icon-textfield'
                    onChange={onChange}
                    value={value}
                    helperText={loginErrors?.userName?.message ? 'Username is required' : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    label='Username'
                    variant='outlined'
                    error={loginErrors?.userName?.message ? true : false}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name='password'
              defaultValue={''}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <TextField
                    name={name}
                    value={value}
                    onChange={onChange}
                    className='w-100 mb-16'
                    label='Password'
                    variant='outlined'
                    helperText={loginErrors?.password?.message ? 'Password is required' : ''}
                    error={loginErrors?.password?.message ? true : false}
                    type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img src={Passlock} alt='lock' />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
            <Typography className='d-flex-j'>
              <Button className='login-btn' id='loginForm' type='submit'>
                LogIn
              </Button>
            </Typography>
            {/* <Typography className='for-txt'>Forgot your password? </Typography> */}
          </form>
        </Typography>
        <Typography component={'div'} className='bottom d-flex-ja'>
          <Typography className='power'>Powered by</Typography>
          <img src={Itec} alt='it' />
        </Typography>
      </Typography>
    </Typography>
  );
};

export const AwsLogin = () => {
  return <Authenticator hideSignUp={true} />;
};
