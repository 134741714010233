/* eslint-disable @typescript-eslint/restrict-template-expressions */
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Worker } from '@react-pdf-viewer/core';
import React from 'react';

import PDFDocument from './PDFDocument';

export const CustomPDFViewer: React.FC<any> = ({ fileUrl, updateSearchPage, jumpIndex }) => {
  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js'>
      <PDFDocument fileUrl={fileUrl} jumpIndex={jumpIndex} updateSearchPage={updateSearchPage} />
    </Worker>
  );
};

export default CustomPDFViewer;
