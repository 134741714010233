import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  deleteRequest,
  getPdfRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'utils/Handler';

export const getSingleData = async (id: string, endpoint: string) => {
 
  const apiParams = {
    url: id !== '' ? `${endpoint}/?id=${id}` : endpoint,
  };

  return await getRequest(apiParams)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error.error.message) {
        errorMessage = error.error.message;
      }

      toast.error(errorMessage || String(error));
    });
};

export const getAllListData = async (endpoint: string, options?: any) => {
  const apiParams = {
    url: endpoint,
  };
  return await getRequest(apiParams, options)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      let errorMessage = ''; // Something went wrong
      if (error && error.error && error.error.message) {
        errorMessage = error.error.message;
      }
      if (errorMessage !== '') {
        toast.error(errorMessage || String(error));
        throw errorMessage.toString();
      }
    });
};

export const addNewData = async (
  data: any,
  endpoint: string,
  options?: any,
) => {
  const apiParams = {
    data,
    url: endpoint,
  };

  return await postRequest(apiParams, options)
    .then((response: any) => {
      // toast.success(response.message, alertOptions);
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error && error.message && typeof error.message === 'string') {
        throw error.message;
      }
      if (typeof error.error.message === 'string') {
        errorMessage = error.error.message;
      } else if (typeof error?.error?.message?.message === 'string') {
        errorMessage = error?.error?.message?.message;
      } else {
        // Array type of message
        errorMessage = error.error.message.toString();
      }
      toast.error(errorMessage || String(error));
      throw errorMessage;
    });
};

export const updateData = async (id: string, data: any, endpoint: string) => {
  const apiParams = {
    data,
    url: id !== '' ? `${endpoint}/${id}` : `${endpoint}`,
  };

  return await putRequest(apiParams)
    .then((response: any) => {
      // toast.success(response.message, alertOptions);
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (typeof error.error.message === 'string') {
        errorMessage = error.error.message;
      } else {
        // Array type of message
        errorMessage = error.error.message.toString();
      }
      toast.error(errorMessage || String(error));
      throw errorMessage;
    });
};

export const deleteData = async (
  id: string,
  endpoint: string,
  payload?: any,
) => {
  const apiParams = {
    data: !_.isEmpty(payload) ? { data: payload } : null,
    url: id !== '' ? `${endpoint}/${id}` : `${endpoint}`,
  };
  // if (!_.isEmpty(data)) {
  //   apiParams['data'] = data;
  // }
  return await deleteRequest(apiParams)
    .then((response: any) => {
      // toast.success(response.message, alertOptions);
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (typeof error.error.message === 'string') {
        errorMessage = error.error.message;
      } else {
        // Array type of message
        errorMessage = error.error.message.toString();
      }
      toast.error(errorMessage || String(error));
      throw errorMessage;
    });
};

export const getS3file = async (key: any, url: string) => {
  const fileCheck = checkContentType(key.key);
  key.contentType = fileCheck;
  return await addNewData(key, url, { responseType: 'blob' });
};

export const getPdfFile = async (key: any, url: string) => {
  const fileCheck = checkContentType(key.key);
  key.contentType = fileCheck;
  return await addNewPdfData(key, url, { responseType: 'blob' });
};

export const addNewPdfData = async (
  data: any,
  endpoint: string,
  options?: any,
) => {
  const apiParams = {
    data,
    url: endpoint,
  };

  return await getPdfRequest(apiParams, options)
    .then((response: any) => {
      // toast.success(response.message, alertOptions);
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error && error.message && typeof error.message === 'string') {
        throw error.message;
      }
      if (typeof error.error.message === 'string') {
        errorMessage = error.error.message;
      } else {
        // Array type of message
        errorMessage = error.error.message.toString();
      }
      toast.error(errorMessage || String(error));
      throw errorMessage;
    });
};

export const checkContentType = (fileKey) => {
  if (fileKey) {
    const formate = fileKey.split('.').pop();
    const fileFormat = formate ? formate.toLowerCase() : '';
    switch (fileFormat) {
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'pdf':
        return 'application/pdf';
      case 'csv':
        return 'text/csv';
      case 'doc':
        return 'application/msword';
      case 'rtf':
        return 'application/rtf';
      case 'txt':
        return 'text/plain';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'bmp':
        return 'image/bmp';
      case 'gif':
        return 'image/gif';
      case 'ico':
        return 'image/vnd.microsoft.icon';
      case 'jpeg' || 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'svg':
        return 'image/svg+xml';
      case 'tif' || 'tiff':
        return 'image/tiff';
      case 'webp':
        return 'image/webp';
      default:
        return '';
    }
  }
};
