/* eslint-disable camelcase */
import * as React from 'react';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Modal,
  styled,
  // ListItemIcon,
  // ListItemText,
  // Menu,
  // MenuItem,
  // MenuList,
  // Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import xtype from 'xtypejs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Menu as SlideMenu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import DownloadIcon from 'assets/icon/downloadicon.svg';
import Clonedoc from 'assets/icon/clonedoc.svg';
// import Cloneim from 'assets/icon/cloneim.svg';
// import Filter from 'assets/icon/filtericon.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
// import { DataStore } from '@aws-amplify/datastore';
// import ITEM from 'models';

import Header from 'components/Header';
// import DataTable from 'components/DataTable/Index';
import Loader from 'components/Loader';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GraphQLSubscription } from '@aws-amplify/api';
import { updateIndexingDashboardPriority, updateItechIndexing_primary } from 'graphql/mutations';
// import { OnUpdateItechIndexingSubscriptionVariables } from 'API';
import * as subscriptions from '../../graphql/subscriptions';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { toast } from 'react-toastify';
// import { useAuthenticator } from '@aws-amplify/ui-react';
import { withStyles } from '@material-ui/core/styles';
// import axios from 'axios';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import * as xlsx from 'xlsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { getAllListData } from 'services/commomService';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { SETUP_ENDPOINT, TABLE_NAME } from 'utils/constant';
import { addNewData, getAllListData } from 'services/commomService';
import jwt from 'jwt-decode';
import dayjs from 'dayjs';
// import { SETUP_ENDPOINT } from 'utils/constant';

// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import { Item as ITEM } from 'models';
// import ContextMenu from 'react-jsx-context-menu';
// import ContentCut from '@mui/icons-material/ContentCut';
// import ContentCopy from '@mui/icons-material/ContentCopy';
// import ContentPaste from '@mui/icons-material/ContentPaste';

const MENU_ID = 'rightMenu';

const RightMenu = (props: { menuId: string; copy: string; data?: any }) => {
  const { menuId, copy, data } = props;
  return (
    <SlideMenu id={menuId} style={{ background: '#eee', boxShadow: '0 4px 4px -6px grey' }}>
      <Item id={copy} onClick={handleItemClick}>
        Copy
      </Item>
      <Item id='copyHeader' onClick={copyHeaderClick}>
        Copy with header
      </Item>
      <Item id='paste' disabled>
        Paste
      </Item>
      <Separator />

      <Submenu label='Export' style={{ background: '#eee', boxShadow: '0 4px 4px -6px grey' }}>
        <Item id='csvExport' onClick={() => exportExcel(data, 'csv')}>
          Export (.csv)
        </Item>
        <Item id='excelXlsx' onClick={() => exportExcel(data, 'xlsx')}>
          Export (.xlsx)
        </Item>
        <Item id='excelXml' disabled>
          Export (.xml)
        </Item>
      </Submenu>
    </SlideMenu>
  );
};

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
      left: '474px',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const handleItemClick = async (props: any) => {
  const { props: prop } = props;
  if (navigator.clipboard && window.isSecureContext) {
    return await navigator.clipboard.writeText(`${prop['row']['signed_email_id']},${prop['key']}`);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement('textarea');
    textArea.value = `${prop['row']['signed_email_id']},${prop['key']}`;
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};
const exportExcel = (data: any, fileType: string) => {
  const overAllData = data?.map((exportData: any) => {
    exportData.created = moment.unix(exportData.created).format('DD-MM-YYYY hh:mm a');
    return exportData;
  });

  const finalExportData = _.map(overAllData, (filterData) => {
    const propertyNames = [
      'signed_email_id',
      'email',
      'task_status',
      'task_lock_by',
      'task_lock_by',
      'created',
      'client_code',
      'cac',
      'description',
      'pages',
      'location',
      'from',
    ];
    return _.pick(filterData, propertyNames);
  });

  const workbook = xlsx.utils.book_new();

  const worksheet = xlsx.utils.json_to_sheet(finalExportData);

  xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelData: any = xlsx.write(workbook, {
    bookType: fileType === 'xlsx' ? 'xlsx' : 'csv',
    type: fileType === 'xlsx' ? 'array' : 'string',
  });

  const blob = new Blob([excelData], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = `${
    overAllData[0]?.priority === 2 ? 'priority' : finalExportData[0]?.task_status
  }_table_data.${fileType}`;
  document.body.appendChild(link);
  link.click();
};
const copyHeaderClick = async (props: any) => {
  const { props: prop } = props;
  if (navigator.clipboard && window.isSecureContext) {
    return await navigator.clipboard.writeText(
      `${prop['header']} ${prop['row']['signed_email_id']},${prop['key']}`,
    );
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = `${prop['header']} ${prop['row']['signed_email_id']},${prop['key']}`;
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

const TextOnlyTooltip = withStyles({
  tooltip: {
    color: 'black !important',
    backgroundColor: 'whiteSmoke !important',
    fontSize: '15px !important',
    boxShadow: '0 0 8px 0px grey !important',
  },
})(Tooltip);

export default function Dashboard() {
  const userDetail: any = jwt(localStorage.getItem('accessToken'));
  const topScroll = React.useRef<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { show } = useContextMenu({
    id: MENU_ID,
  });
  const cookies = new Cookies();
  // const priority = 5;
  const tabRef = React.useRef(null);
  const [loader, setLoader] = React.useState<boolean>(true);
  // const [value, setValue] = React.useState(
  //   searchParams.get('tabName') ? searchParams.get('tabName') : 'pending',
  // );
  // const [pendingOpen, setPendingOpen] = React.useState({ show: false, index: 0 });
  const [expandInfo, setExpandInfo] = React.useState<any>({});
  // const [priorityOpen, setPriorityOpen] = React.useState({ show: false, index: 0 });
  const [pendingTableData, setPendingTableData] = React.useState<any>([]);
  const [searchCount, setSearchCount] = React.useState<any>('');
  const [completedTableData, setCompletedTableData] = React.useState<any>([]);
  const [priorityTableData, setPriorityTableData] = React.useState<any>([]);
  const [rescanTableData, setRescanTableData] = React.useState<any>([]);
  const [iclarifyTableData, setIclarifyTableData] = React.useState<any>([]);
  const [tiffFile, setTiffFile] = React.useState<any>();
  const [downloadMlresult, setDownloadMlResult] = React.useState<any>({
    mlRes: {},
    mlResFileName: '',
  });
  // const [dataTableLoader, setDataTableLoader] = React.useState(true);
  // const [params, setParams] = React.useState<any>({
  //   table_name: '',
  //   filters: '',
  //   sort_by: '',
  //   asc: '',
  //   page: 1,
  //   page_size: 50,
  // });
  const [filterParams, setFilterParams] = React.useState<any>({
    email: '',
    source: '',
    status: '',
    assign: '',
    created: null,
    client: '',
    scac: '',
    description: '',
    pages: '',
    location: '',
    from: '',
    asc: true,
    sortby: 'created',
  });
  // const [allFilterParams, setAllFilterParams] = React.useState<any>({
  //   value: '',
  // });

  const [completedFilterParams, setCompletedFilterParams] = React.useState<any>({
    email: '',
    source: '',
    status: '',
    assign: '',
    created: null,
    completed: null,
    client: '',
    scac: '',
    description: '',
    pages: '',
    location: '',
    from: '',
    asc: false,
    sortby: 'completed_at',
  });
  const [rescanFilterParams, setRescanFilterParams] = React.useState<any>({
    email: '',
    source: '',
    status: '',
    assign: '',
    created: null,
    completed: null,
    client: '',
    scac: '',
    description: '',
    pages: '',
    location: '',
    from: '',
    asc: false,
    sortby: 'created',
  });
  const [iclarifyFilterParams, setIclarifyFilterParams] = React.useState<any>({
    email: '',
    source: '',
    status: '',
    assign: '',
    created: null,
    completed: null,
    client: '',
    scac: '',
    description: '',
    pages: '',
    location: '',
    from: '',
    asc: false,
    sortby: 'created',
  });
  const [priorityFilterParams, setPriorityFilterParams] = React.useState<any>({
    email: '',
    source: '',
    status: '',
    assign: '',
    created: null,
    client: '',
    scac: '',
    description: '',
    pages: '',
    location: '',
    from: '',
    asc: true,
    sortby: 'created',
  });
  const searchRegex = /^[a-zA-Z0-9!$%()*+,-./:;<=>?@[\]^_`{|}~"]*$/;
  const [nextToken, setNextToken] = React.useState<number>(1);
  const [priorityNextToken, setPriorityNextToken] = React.useState<number>(1);
  const [rescanNextToken, setRescanNextToken] = React.useState<number>(1);
  const [iclarifyNextToken, setIclarifyNextToken] = React.useState<number>(1);
  const [completedNextToken, setCompletedNextToken] = React.useState<number>(1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [cloneDoc, setCloneDoc] = React.useState<null | HTMLElement>(null);
  const [cloneByfullIndex, setCloneByFullIndex] = React.useState<any>();
  const DocOpen = Boolean(cloneDoc);
  const [getMoreLoader, setGetMoreLoader] = React.useState(false);
  const navigate = useNavigate();
  // const { user } = useAuthenticator();
  // const [limit, setLimit] = React.useState(100);
  const [modelTrain, setModalTrain] = React.useState<any>([]);
  const [modalOpen, setModalOpen] = React.useState<any>({ isOpen: false, data: '' });
  const [tableCount, setTableCount] = React.useState<any>({
    pendingCount: 0,
    priorityCount: 0,
    completedCount: 0,
    rescanCount: 0,
    iClarifyCount: 0,
  });

  const toggleAcordion = (indexId: number) => {
    setExpandInfo((pre) => ({
      ...pre,
      [indexId]: !pre[indexId],
    }));
  };

  const menuOpen = (
    event: React.MouseEvent<HTMLElement>,
    data?: any,
    mlResult?: any,
    mlIndex?: number,
  ) => {
    setTiffFile(data);
    setDownloadMlResult({ mlRes: JSON.parse(mlResult)[mlIndex], mlResFileName: data?.imageId });
    setAnchorEl(event.currentTarget);
  };

  const menuClose = () => {
    setAnchorEl(null);
    setTiffFile(null);
    setDownloadMlResult({
      mlRes: {},
      mlResFileName: '',
    });
  };

  const DocMenuOpen = (event: React.MouseEvent<HTMLElement>, index?: any) => {
    setCloneByFullIndex(index);
    setCloneDoc(event.currentTarget);
  };
  const DocMenuClose = () => {
    setCloneDoc(null);
    setCloneByFullIndex('');
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // cookies.set('tabName', newValue, {
    //   expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    // });
    setSearchParams({ tabName: newValue });
    setLoader(true);
    // setValue(newValue);
    setPendingTableData([]);
    setCompletedTableData([]);
    setRescanTableData([]);
    setIclarifyTableData([]);
    setPriorityTableData([]);
    setNextToken(1);
    setPriorityNextToken(1);
    setRescanNextToken(1);
    setIclarifyNextToken(1);
    setCompletedNextToken(1);
    // searchFilterData();
  };
  const setCookies = (key: string, value: any) => {
    filterParams[key] = value;
    cookies.set('pending', JSON.stringify(filterParams), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });
  };
  const setCompletedCookies = (key: string, value: any) => {
    completedFilterParams[key] = value;
    cookies.set('completed', JSON.stringify(completedFilterParams), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });
  };
  const setPriorityCookies = (key: string, value: any) => {
    priorityFilterParams[key] = value;
    cookies.set('priority', JSON.stringify(priorityFilterParams), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });
  };
  const setRescanCookies = (key: string, value: any) => {
    rescanFilterParams[key] = value;
    cookies.set('rescan', JSON.stringify(rescanFilterParams), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });
  };

  const setIclarifyCookies = (key: string, value: any) => {
    iclarifyFilterParams[key] = value;
    cookies.set('iclarify', JSON.stringify(iclarifyFilterParams), {
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });
  };

  const searchRegexCheck = (value: string) => {
    return searchRegex.test(value) ? value : '';
  };

  const taskLockUpdate = async (signedEmail: string, created: number, taskUnlock?: string) => {
    try {
      const taskLockRes = await API.graphql(
        graphqlOperation(updateItechIndexing_primary, {
          input: {
            created: created,
            display_task: true,
            signed_email_id: signedEmail.replace(/-/g, ''),
            task_lock: taskUnlock === 'unlock' ? false : true,
            task_lock_by: taskUnlock === 'unlock' ? '' : userDetail.userName,
          },
        }),
      );
      if (taskLockRes) {
        await getAllListData(
          `${SETUP_ENDPOINT.update}?table_name=${TABLE_NAME.pending}&set_data=${JSON.stringify({
            task_lock: taskUnlock === 'unlock' ? false : true,
            task_lock_by: taskUnlock === 'unlock' ? '' : userDetail?.userName,
          })}&conditions=${JSON.stringify({ signed_email_id: signedEmail })}`,
        );
      }
      setModalOpen({ isOpen: false, data: '' });
      if (taskUnlock === 'unlock') toast.success('Task Unlocked successfully');
    } catch (error) {
      setModalOpen({ isOpen: false, data: '' });
    }
  };

  const cloneByFull = async (signedEmail: string) => {
    addNewData(
      {
        email_signature: signedEmail.replace(/-/g, ''),
        indexing: 0,
        clone_image: false,
        cloned_user: userDetail?.userName,
      },
      SETUP_ENDPOINT.clone,
    ).then(() => {
      getAllListData(
        `${SETUP_ENDPOINT.update}?table_name=${TABLE_NAME.completed}&set_data=${JSON.stringify({
          clone_status: 'yes',
        })}&conditions=${JSON.stringify({ signed_email_id: signedEmail })}`,
      );
      DocMenuClose();
      toast.success('Document Cloned');
    });
  };

  const cloneByImg = (signedEmail: string) => {
    addNewData(
      {
        email_signature: signedEmail.replace(/-/g, ''),
        indexing: cloneByfullIndex,
        clone_image: true,
        cloned_user: userDetail?.userName,
      },
      SETUP_ENDPOINT.clone,
    ).then(async () => {
      await getAllListData(
        `${SETUP_ENDPOINT.update}?table_name=${TABLE_NAME.completed}&set_data=${JSON.stringify({
          clone_status: 'yes',
        })}&conditions=${JSON.stringify({ signed_email_id: signedEmail })}`,
      );
      DocMenuClose();
      toast.success('Image Cloned');
    });
  };
  const handleOnclickLock = (row) => {
    setModalOpen({ isOpen: true, data: row });
  };
  const priorityTaskLockUpdate = async (
    signedEmail: string,
    created: number,
    taskUnlock?: string,
  ) => {
    try {
      const taskLockRes = await API.graphql(
        graphqlOperation(updateIndexingDashboardPriority, {
          input: {
            created: created,
            signed_email_id: signedEmail.replace(/-/g, ''),
            task_lock: taskUnlock === 'unlock' ? false : true,
            task_lock_by: taskUnlock === 'unlock' ? '' : userDetail?.userName,
          },
        }),
      );
      if (taskLockRes) {
        await getAllListData(
          `${SETUP_ENDPOINT.update}?table_name=${TABLE_NAME.priority}&set_data=${JSON.stringify({
            task_lock: taskUnlock === 'unlock' ? false : true,
            task_lock_by: taskUnlock === 'unlock' ? '' : userDetail?.userName,
          })}&conditions=${JSON.stringify({ signed_email_id: signedEmail })}`,
        );
      }
      setModalOpen({ isOpen: false, data: '' });
      if (taskUnlock === 'unlock') toast.success('Task Unlocked successfully');
    } catch (error) {
      setModalOpen({ isOpen: false, data: '' });
      console.warn(error);
    }
  };

  const handleContextMenu = (event, row, copiedValue, header) => {
    show({
      event,
      props: {
        key: copiedValue,
        row: row,
        header: header,
      },
    });
  };

  const navDocumentView = (data: any, taskStatus: string, skipIndex?: number) => {
    if (taskStatus === 'pending') {
      if (JSON.parse(data?.task_info).split_info?.length) {
        navigate(`/document-view/pending?id=${data?.signed_email_id}`, {
          state: {
            allData: JSON.parse(data?.task_info),
            signedEmail: data?.signed_email_id,
            clientCode: data.client_code,
            created: data.created,
            scacCode: data.scac,
            siteName: data?.location,
            pages: data.pages,
            quedAt: JSON.parse(data?.task_info).qued_at || '',
            Priority: data?.priority || '',
            fileLog: JSON.parse(data?.file_log),
            tabType: 'pending',
            clientLocation: data?.from ? data?.from : '',
            email: data.email,
            description: data.description,
            from: data.from,
            source: data.source,
            clLocation: data.location,
            fs: data.file_source,
            skipIndex: skipIndex,
            messageId: data.message_id,
            clientCodeRespict: data.client_code_receipt,
            scacCodeReceipt: data.scac_code_receipt,
            indexingStatus: data.indexing_status,
            totalAttachment: data.total_attachment,
            attachmentSize: data.attachment_size,
            cloneStatus: data.clone_status,
          },
        });

        taskLockUpdate(data.signed_email_id, data.created);
      } else {
        toast.error('No data Found');
      }
    } else if (taskStatus === 'priority') {
      if (JSON.parse(data?.task_info).split_info?.length) {
        navigate(`/document-view/priority?id=${data?.signed_email_id}`, {
          state: {
            allData: JSON.parse(data?.task_info),
            signedEmail: data?.signed_email_id,
            clientCode: data.client_code,
            created: data.created,
            scacCode: data.scac,
            siteName: data?.location,
            pages: data.pages,
            quedAt: JSON.parse(data?.task_info).qued_at || '',
            Priority: data?.priority || '',
            fileLog: JSON.parse(data?.file_log),
            tabType: 'priority',
            // clientLocation: data?.from ? data?.from : '',
            email: data.email,
            description: data.description,
            from: data.from,
            source: data.source,
            clLocation: data.location,
            fs: data.file_source,
            skipIndex: skipIndex,
            messageId: data.message_id,
            clientCodeRespict: data.client_code_receipt,
            scacCodeReceipt: data.scac_code_receipt,
            indexingStatus: data.indexing_status,
            totalAttachment: data.total_attachment,
            attachmentSize: data.attachment_size,
            cloneStatus: data.clone_status,
          },
        });
        priorityTaskLockUpdate(data.signed_email_id, data.created);
      } else {
        toast.error('No data Found');
      }
    } else if (taskStatus === 'completed') {
      if (JSON.parse(data?.task_info)?.split_info.length === 1) {
        navigate(`/complete-document-view/completed?id=${data?.signed_email_id}`, {
          state: {
            splitInfo: JSON.parse(data?.task_info)?.split_info[0],
            priority: data.priority || 0,
            pages: Number(data?.pages) || 0,
            splitIndex: JSON.parse(data?.task_info).split_index,
            fileSource: JSON.parse(JSON.parse(data.file_source)),
          },
        });
      } else {
        const completedBindData = [];
        JSON.parse(data?.task_info)?.split_info?.forEach((completedData: any) => {
          completedData?.forEach((bindData: any) => {
            completedBindData.push(bindData);
          });
        });
        navigate(`/complete-document-view/completed?id=${data?.signed_email_id}`, {
          state: {
            splitInfo: completedBindData,
            priority: JSON.parse(data?.task_info).priority || '',
            pages: Number(data?.pages) || 0,
            splitIndex: JSON.parse(data?.task_info).split_index,
            fileSource: JSON.parse(JSON.parse(data.file_source)),
          },
        });
      }
    } else if (taskStatus === 'rescan') {
      const { split_info } = JSON.parse(data?.task_info);
      const overallData = [];

      split_info?.forEach((taskData) => taskData?.forEach((subTask) => overallData.push(subTask)));

      const allData = {
        isPlit: false,
        totalSplit: 0,
        qued_at: '',
        assigned_to: '',
        split_info: overallData,
      };
      navigate(`/document-view/rescan?id=${data?.signed_email_id}`, {
        state: {
          allData: allData,
          signedEmail: data?.signed_email_id,
          clientCode: data.client_code,
          created: data.created,
          scacCode: data.scac,
          siteName: data?.location,
          pages: data?.pages,
          quedAt: JSON.parse(data?.task_info).qued_at || '',
          Priority: data?.priority || '',
          fileLog: JSON.parse(data?.file_log),
          tabType: 'rescan',
          clientLocation: data?.from ? data?.from : '',
          email: data.email,
          description: data.description,
          from: data.from,
          source: data.source,
          clLocation: data.location,
          fs: data.file_source,
          skipIndex: skipIndex,
          messageId: data.message_id,
          clientCodeRespict: data.client_code_receipt,
          scacCodeReceipt: data.scac_code_receipt,
          indexingStatus: data.indexing_status,
          totalAttachment: data.total_attachment,
          attachmentSize: data.attachment_size,
          cloneStatus: data.clone_status,
        },
      });
    } else if (taskStatus === 'iclarify') {
      if (JSON.parse(data?.task_info).split_info?.length) {
        JSON.parse(data?.task_info).split_info.map((e) => {
          if (xtype.type(e) === 'object') {
            navigate(`/document-view/iclarify?id=${data?.signed_email_id}`, {
              state: {
                allData: JSON.parse(data?.task_info),
                signedEmail: data?.signed_email_id,
                clientCode: data.client_code,
                created: data.created,
                scacCode: data.scac,
                siteName: data?.location,
                pages: data.pages,
                quedAt: JSON.parse(data?.task_info).qued_at || '',
                Priority: data?.priority || '',
                fileLog: JSON.parse(data?.file_log),
                tabType: 'iclarify',
                clientLocation: data?.from ? data?.from : '',
                email: data.email,
                description: data.description,
                from: data.from,
                source: data.source,
                clLocation: data.location,
                fs: data.file_source,
                clarifyDetails: JSON.parse(data.clarify_requested_by),
                // skipIndex: skipIndex,
                messageId: data.message_id,
                clientCodeRespict: data.client_code_receipt,
                scacCodeReceipt: data.scac_code_receipt,
                indexingStatus: data.indexing_status,
                totalAttachment: data.total_attachment,
                attachmentSize: data.attachment_size,
                cloneStatus: data.clone_status,
              },
            });
          } else {
            const { split_info } = JSON.parse(data?.task_info);
            const overallData = [];

            split_info?.forEach((taskData) =>
              taskData?.forEach((subTask) => overallData.push(subTask)),
            );

            const allData = {
              isPlit: false,
              totalSplit: 0,
              qued_at: '',
              assigned_to: '',
              split_info: overallData,
            };
            navigate(`/document-view/iclarify?id=${data?.signed_email_id}`, {
              state: {
                allData: allData,
                signedEmail: data?.signed_email_id,
                clientCode: data.client_code,
                created: data.created,
                scacCode: data.scac,
                siteName: data?.location,
                pages: data.pages,
                quedAt: JSON.parse(data?.task_info).qued_at || '',
                Priority: data?.priority || '',
                fileLog: JSON.parse(data?.file_log),
                tabType: 'iclarify',
                // clientLocation: data?.from ? data?.from : '',
                email: data.email,
                description: data.description,
                from: data.from,
                source: data.source,
                clLocation: data.location,
                fs: data.file_source,
                clarifyDetails: JSON.parse(data.clarify_requested_by),
                // skipIndex: skipIndex,
                messageId: data.message_id,
                clientCodeRespict: data.client_code_receipt,
                scacCodeReceipt: data.scac_code_receipt,
                indexingStatus: data.indexing_status,
                totalAttachment: data.total_attachment,
                attachmentSize: data.attachment_size,
                cloneStatus: data.clone_status,
              },
            });
          }
        });

        // priorityTaskLockUpdate(data.signed_email_id, data.created);
      } else {
        toast.error('No data Found');
      }
    }
  };
  const uriTourl = (uri: string) => {
    const pattern = /s3:\/\/(.+?)\/(.+)/;
    const match = uri.match(pattern);
    if (match) {
      const bucket = match[1];
      const key = match[2];
      return `https://${bucket}.s3.ap-south-1.amazonaws.com/batching/${key}`;
    } else {
      return null;
    }
  };

  const encodeUriTourl = (uri: string) => {
    const pattern = /s3:\/\/(.+?)\/(.+)/;
    const match = uri.match(pattern);
    if (match) {
      const bucket = match[1];
      const key = encodeURIComponent(match[2]);
      return `https://${bucket}.s3.ap-south-1.amazonaws.com/${key}`;
    } else {
      return null;
    }
  };

  const downloadJsonAsFile = (jsonData: any, filename: string) => {
    const jsonString = JSON.stringify(jsonData, null, 2); // Convert JSON data to string with pretty formatting
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Programmatically trigger the download
    a.click();

    // Clean up resources
    window.URL.revokeObjectURL(url);
  };

  const tableDataCount = async () => {
    getAllListData(`${SETUP_ENDPOINT.count}`)
      .then((res: any) => {
        setTableCount({
          pendingCount: res?.pending_count,
          priorityCount: res?.priority_count,
          completedCount: res?.completed_count,
          rescanCount: res?.clone_count,
          iClarifyCount: res?.iclarify_count,
        });
      })
      .catch((err) => console.log(err));
  };

  const clearFilter = () => {
    if (searchParams.get('tabName') === 'pending') {
      setNextToken(1);
      setFilterParams({
        email: '',
        source: '',
        status: '',
        assign: '',
        created: '',
        client: '',
        scac: '',
        description: '',
        pages: '',
        location: '',
        from: '',
        asc: true,
        sortby: 'created',
      });
      cookies.set(
        'pending',
        JSON.stringify({
          email: '',
          source: '',
          status: '',
          assign: '',
          created: '',
          client: '',
          scac: '',
          description: '',
          pages: '',
          location: '',
          from: '',
        }),
      );
    } else if (searchParams.get('tabName') === 'completed') {
      setCompletedNextToken(1);
      setCompletedFilterParams({
        email: '',
        source: '',
        status: '',
        assign: '',
        created: '',
        completed: '',
        client: '',
        scac: '',
        description: '',
        pages: '',
        location: '',
        from: '',
        asc: false,
        sortby: 'completed_at',
      });
      cookies.set(
        'completed',
        JSON.stringify({
          email: '',
          source: '',
          status: '',
          assign: '',
          created: '',
          client: '',
          scac: '',
          description: '',
          pages: '',
          location: '',
          from: '',
        }),
      );
    } else if (searchParams.get('tabName') === 'rescan') {
      setRescanNextToken(1);
      setRescanFilterParams({
        email: '',
        source: '',
        status: '',
        assign: '',
        created: '',
        client: '',
        scac: '',
        description: '',
        pages: '',
        location: '',
        from: '',
        asc: true,
        sortby: 'created',
      });
      cookies.set(
        'rescan',
        JSON.stringify({
          email: '',
          source: '',
          status: '',
          assign: '',
          created: '',
          client: '',
          scac: '',
          description: '',
          pages: '',
          location: '',
          from: '',
        }),
      );
    } else if (searchParams.get('tabName') === 'iclarify') {
      setIclarifyNextToken(1);
      setIclarifyFilterParams({
        email: '',
        source: '',
        status: '',
        assign: '',
        created: '',
        client: '',
        scac: '',
        description: '',
        pages: '',
        location: '',
        from: '',
        asc: true,
        sortby: 'created',
      });
      cookies.set(
        'iclarify',
        JSON.stringify({
          email: '',
          source: '',
          status: '',
          assign: '',
          created: '',
          client: '',
          scac: '',
          description: '',
          pages: '',
          location: '',
          from: '',
        }),
      );
    } else if (searchParams.get('tabName') === 'priority') {
      setPriorityNextToken(1);
      setPriorityFilterParams({
        email: '',
        source: '',
        status: '',
        assign: '',
        created: '',
        client: '',
        scac: '',
        description: '',
        pages: '',
        location: '',
        from: '',
        asc: true,
        sortby: 'created',
      });
      cookies.set(
        'priority',
        JSON.stringify({
          email: '',
          source: '',
          status: '',
          assign: '',
          created: '',
          client: '',
          scac: '',
          description: '',
          pages: '',
          location: '',
          from: '',
        }),
      );
    }
  };

  const searchFilterData = async () => {
    if (searchParams.get('tabName') === 'pending') {
      const filterInputs = {
        priority: ['eq', 5],
        display_task: ['eq', true],
        task_status: ['eq', 'pending'],
        ...(cookies.get('pending')?.email
          ? { email: ['startswith', encodeURIComponent(cookies.get('pending')?.email)] }
          : ''),
        ...(cookies.get('pending')?.source
          ? { source: ['startswith', cookies.get('pending')?.source] }
          : ''),
        ...(cookies.get('pending')?.pages
          ? { pages: ['eq', encodeURIComponent(cookies.get('pending')?.pages)] }
          : ''),
        ...(cookies.get('pending')?.created
          ? {
              created: [
                'between',
                [
                  moment.unix(cookies.get('pending')?.created).startOf('day').utc().unix(),
                  moment.unix(cookies.get('pending')?.created).endOf('day').utc().unix(),
                ],
              ],
            }
          : ''),
        ...(cookies.get('pending')?.assign
          ? { task_lock_by: ['startswith', encodeURIComponent(cookies.get('pending')?.assign)] }
          : ''),
        ...(cookies.get('pending')?.client
          ? { client_code: ['startswith', encodeURIComponent(cookies.get('pending')?.client)] }
          : ''),
        ...(cookies.get('pending')?.scac
          ? { scac: ['startswith', cookies.get('pending')?.scac] }
          : ''),
        ...(cookies.get('pending')?.description
          ? { description: ['contains', encodeURIComponent(cookies.get('pending')?.description)] }
          : ''),
        ...(cookies.get('pending')?.location
          ? { location: ['startswith', encodeURIComponent(cookies.get('pending')?.location)] }
          : ''),
        ...(cookies.get('pending')?.from
          ? { from: ['startswith', encodeURIComponent(cookies.get('pending')?.from)] }
          : ''),
      };

      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.pending}&filters=${JSON.stringify(
          filterInputs,
        )}&asc=${filterParams?.asc}&sort_by=${filterParams?.sortby}`,
      ).then((res: any) => {
        setPendingTableData(res?.data);
        setSearchCount(res?.total_count);
        setLoader(false);
      });
      // sortSearchData.sort((a, b) => String(a.created).localeCompare(String(b.created)));
    } else if (searchParams.get('tabName') === 'completed') {
      const filterInputs = {
        task_status: ['eq', 'completed'],
        ...(cookies.get('completed')?.email
          ? { email: ['startswith', cookies.get('completed')?.email] }
          : ''),
        ...(cookies.get('completed')?.source
          ? { source: ['startswith', cookies.get('completed')?.source] }
          : ''),
        ...(cookies.get('completed')?.pages
          ? { pages: ['eq', cookies.get('completed')?.pages] }
          : ''),
        ...(cookies.get('completed')?.assign
          ? { iscompleted_by: ['startswith', cookies.get('completed')?.assign] }
          : ''),
        ...(cookies.get('completed')?.created
          ? {
              created: [
                'between',
                [
                  moment.unix(cookies.get('completed')?.created).startOf('day').utc().unix(),
                  moment.unix(cookies.get('completed')?.created).endOf('day').utc().unix(),
                ],
              ],
            }
          : ''),
        ...(cookies.get('completed')?.completed
          ? {
              completed_at: [
                'between',
                [
                  moment.unix(cookies.get('completed')?.completed).startOf('day').utc().unix(),
                  moment.unix(cookies.get('completed')?.completed).endOf('day').utc().unix(),
                ],
              ],
            }
          : ''),
        ...(cookies.get('completed')?.client
          ? { client_code: ['startswith', cookies.get('completed')?.client] }
          : ''),
        ...(cookies.get('completed')?.scac
          ? { scac: ['startswith', cookies.get('completed')?.scac] }
          : ''),
        ...(cookies.get('completed')?.description
          ? { description: ['contains', cookies.get('completed')?.description] }
          : ''),
        ...(cookies.get('completed')?.location
          ? { location: ['startswith', cookies.get('completed')?.location] }
          : ''),
        ...(cookies.get('completed')?.from
          ? { from: ['startswith', cookies.get('completed')?.from] }
          : ''),
      };
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.completed}&filters=${encodeURIComponent(
          JSON.stringify(filterInputs),
        )}&asc=${completedFilterParams?.asc}&sort_by=${completedFilterParams?.sortby}`,
      ).then((res) => {
        setCompletedTableData(res?.data);
        setSearchCount(res?.total_count);

        setLoader(false);
      });
    } else if (searchParams.get('tabName') === 'priority') {
      const filterInputs = {
        task_status: ['eq', 'pending'],
        priority: ['eq', 2],
        display_task: ['eq', true],
        ...(cookies.get('priority')?.email
          ? { email: ['startswith', cookies.get('priority')?.email] }
          : ''),
        ...(cookies.get('priority')?.source
          ? { source: ['startswith', cookies.get('priority')?.source] }
          : ''),
        ...(cookies.get('priority')?.pages
          ? { pages: ['eq', cookies.get('priority')?.pages] }
          : ''),
        ...(cookies.get('priority')?.assign
          ? { task_lock_by: ['startswith', cookies.get('priority')?.assign] }
          : ''),
        ...(cookies.get('priority')?.created
          ? {
              created: [
                'between',
                [
                  moment.unix(cookies.get('priority')?.created).startOf('day').utc().unix(),
                  moment.unix(cookies.get('priority')?.created).endOf('day').utc().unix(),
                ],
              ],
            }
          : ''),
        ...(cookies.get('priority')?.client
          ? { client_code: ['startswith', cookies.get('priority')?.client] }
          : ''),
        ...(cookies.get('priority')?.scac
          ? { scac: ['startswith', cookies.get('priority')?.scac] }
          : ''),
        ...(cookies.get('priority')?.description
          ? { description: ['contains', cookies.get('priority')?.description] }
          : ''),
        ...(cookies.get('priority')?.location
          ? { location: ['startswith', cookies.get('priority')?.location] }
          : ''),
        ...(cookies.get('priority')?.from
          ? { from: ['startswith', cookies.get('priority')?.from] }
          : ''),
      };
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.priority}&filters=${encodeURIComponent(
          JSON.stringify(filterInputs),
        )}&asc=${priorityFilterParams?.asc}&sort_by=${priorityFilterParams?.sortby}`,
      ).then((res) => {
        setPriorityTableData(res?.data);
        setSearchCount(res?.total_count);
        setLoader(false);
      });
    } else if (searchParams.get('tabName') === 'rescan') {
      const filterInputs = {
        task_status: ['eq', 'pending'],
        display_task: ['eq', true],
        ...(cookies.get('rescan')?.email
          ? { email: ['startswith', cookies.get('rescan')?.email] }
          : ''),
        ...(cookies.get('rescan')?.source
          ? { source: ['startswith', cookies.get('rescan')?.source] }
          : ''),
        ...(cookies.get('rescan')?.pages ? { pages: ['eq', cookies.get('rescan')?.pages] } : ''),
        ...(cookies.get('rescan')?.assign
          ? { task_lock_by: ['startswith', cookies.get('rescan')?.assign] }
          : ''),
        ...(cookies.get('rescan')?.created
          ? {
              created: [
                'between',
                [
                  moment.unix(cookies.get('rescan')?.created).startOf('day').utc().unix(),
                  moment.unix(cookies.get('rescan')?.created).endOf('day').utc().unix(),
                ],
              ],
            }
          : ''),
        ...(cookies.get('rescan')?.client
          ? { client_code: ['startswith', cookies.get('rescan')?.client] }
          : ''),
        ...(cookies.get('rescan')?.scac
          ? { scac: ['startswith', cookies.get('rescan')?.scac] }
          : ''),
        ...(cookies.get('rescan')?.description
          ? { description: ['contains', cookies.get('rescan')?.description] }
          : ''),
        ...(cookies.get('rescan')?.location
          ? { location: ['startswith', cookies.get('rescan')?.location] }
          : ''),
        ...(cookies.get('rescan')?.from
          ? { from: ['startswith', cookies.get('rescan')?.from] }
          : ''),
      };
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.clone}&filters=${encodeURIComponent(
          JSON.stringify(filterInputs),
        )}&asc=${rescanFilterParams?.asc}&sort_by=${rescanFilterParams?.sortby}`,
      ).then((res) => {
        setRescanTableData(res.data);
        setSearchCount(res.total_count);
        setLoader(false);
      });
    } else if (searchParams.get('tabName') === 'iclarify') {
      const filterInputs = {
        task_status: ['eq', 'pending'],
        display_task: ['eq', true],
        ...(cookies.get('iclarify')?.email
          ? { email: ['startswith', cookies.get('iclarify')?.email] }
          : ''),
        ...(cookies.get('iclarify')?.source
          ? { source: ['startswith', cookies.get('iclarify')?.source] }
          : ''),
        ...(cookies.get('iclarify')?.pages
          ? { pages: ['eq', cookies.get('iclarify')?.pages] }
          : ''),
        ...(cookies.get('iclarify')?.assign
          ? { task_lock_by: ['startswith', cookies.get('iclarify')?.assign] }
          : ''),
        ...(cookies.get('iclarify')?.created
          ? {
              created: [
                'between',
                [
                  moment.unix(cookies.get('iclarify')?.created).startOf('day').utc().unix(),
                  moment.unix(cookies.get('iclarify')?.created).endOf('day').utc().unix(),
                ],
              ],
            }
          : ''),
        ...(cookies.get('iclarify')?.client
          ? { client_code: ['startswith', cookies.get('iclarify')?.client] }
          : ''),
        ...(cookies.get('iclarify')?.scac
          ? { scac: ['startswith', cookies.get('iclarify')?.scac] }
          : ''),
        ...(cookies.get('iclarify')?.description
          ? { description: ['contains', cookies.get('iclarify')?.description] }
          : ''),
        ...(cookies.get('iclarify')?.location
          ? { location: ['startswith', cookies.get('iclarify')?.location] }
          : ''),
        ...(cookies.get('iclarify')?.from
          ? { from: ['startswith', cookies.get('iclarify')?.from] }
          : ''),
      };
      await getAllListData(
        `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.iclarify}&filters=${encodeURIComponent(
          JSON.stringify(filterInputs),
        )}&asc=${iclarifyFilterParams?.asc}&sort_by=${iclarifyFilterParams?.sortby}`,
      ).then((res) => {
        setIclarifyTableData(res.data);
        setSearchCount(res?.total_count);
        setLoader(false);
      });
    }
  };

  const sortTableData = (value?: string, sort?: boolean) => {
    if (searchParams.get('tabName') === 'pending') {
      setFilterParams((pre) => ({ ...pre, asc: sort, sortby: value }));
    } else if (searchParams.get('tabName') === 'completed') {
      setCompletedFilterParams((pre) => ({ ...pre, asc: sort, sortby: value }));
    } else if (searchParams.get('tabName') === 'priority') {
      setPriorityFilterParams((pre) => ({ ...pre, asc: sort, sortby: value }));
    } else if (searchParams.get('tabName') === 'rescan') {
      setRescanFilterParams((pre) => ({ ...pre, asc: sort, sortby: value }));
    } else if (searchParams.get('tabName') === 'iclarify') {
      setIclarifyFilterParams((pre) => ({ ...pre, asc: sort, sortby: value }));
    }
  };
  const pendingGetMoreList = async () => {
    setGetMoreLoader(true);
    const count = Number(nextToken + 1);
    setNextToken(count);
    const filterInputs = {
      task_status: ['eq', 'pending'],
      priority: ['eq', '5'],
      display_task: ['eq', 'true'],
      ...(cookies.get('pending')?.email
        ? { email: ['startswith', cookies.get('pending')?.email] }
        : ''),
      ...(cookies.get('pending')?.source
        ? { source: ['startswith', cookies.get('pending')?.source] }
        : ''),
      ...(cookies.get('pending')?.pages ? { pages: ['eq', cookies.get('pending')?.pages] } : ''),
      ...(cookies.get('pending')?.assign
        ? { task_lock_by: ['startswith', cookies.get('pending')?.assign] }
        : ''),
      ...(cookies.get('pending')?.created
        ? {
            created: [
              'between',
              [
                moment.unix(cookies.get('pending')?.created).startOf('day').utc().unix(),
                moment.unix(cookies.get('pending')?.created).endOf('day').utc().unix(),
              ],
            ],
          }
        : ''),
      ...(cookies.get('pending')?.client
        ? { client_code: ['startswith', cookies.get('pending')?.client] }
        : ''),
      ...(cookies.get('pending')?.scac
        ? { scac: ['startswith', cookies.get('pending')?.scac] }
        : ''),
      ...(cookies.get('pending')?.description
        ? { description: ['contains', cookies.get('pending')?.description] }
        : ''),
      ...(cookies.get('pending')?.location
        ? { location: ['startswith', cookies.get('pending')?.location] }
        : ''),
      ...(cookies.get('pending')?.from
        ? { from: ['startswith', cookies.get('pending')?.from] }
        : ''),
    };
    await getAllListData(
      `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.pending}&filters=${encodeURIComponent(
        JSON.stringify(filterInputs),
      )}&page=${count}&asc=${filterParams?.asc}&sort_by=${filterParams?.sortby}`,
    ).then((res) => {
      Array.isArray(res.data) &&
        res.data.length > 0 &&
        setPendingTableData((pre) => [...pre, ...res.data]);
      setGetMoreLoader(false);
    });
  };
  const priorityGetMoreList = async () => {
    setGetMoreLoader(true);
    const count = Number(priorityNextToken + 1);
    setPriorityNextToken(count);
    const filterInputs = {
      task_status: ['eq', 'pending'],
      priority: ['eq', 2],
      display_task: ['eq', 'true'],
      ...(cookies.get('priority')?.email
        ? { email: ['startswith', cookies.get('priority')?.email] }
        : ''),
      ...(cookies.get('priority')?.source
        ? { source: ['startswith', cookies.get('priority')?.source] }
        : ''),
      ...(cookies.get('priority')?.pages ? { pages: ['eq', cookies.get('priority')?.pages] } : ''),
      ...(cookies.get('priority')?.assign
        ? { task_lock_by: ['startswith', cookies.get('priority')?.assign] }
        : ''),
      ...(cookies.get('priority')?.created
        ? {
            created: [
              'between',
              [
                moment.unix(cookies.get('priority')?.created).startOf('day').utc().unix(),
                moment.unix(cookies.get('priority')?.created).endOf('day').utc().unix(),
              ],
            ],
          }
        : ''),
      ...(cookies.get('priority')?.client
        ? { client_code: ['startswith', cookies.get('priority')?.client] }
        : ''),
      ...(cookies.get('priority')?.scac
        ? { scac: ['startswith', cookies.get('priority')?.scac] }
        : ''),
      ...(cookies.get('priority')?.description
        ? { description: ['contains', cookies.get('priority')?.description] }
        : ''),
      ...(cookies.get('priority')?.location
        ? { location: ['startswith', cookies.get('priority')?.location] }
        : ''),
      ...(cookies.get('priority')?.from
        ? { from: ['startswith', cookies.get('priority')?.from] }
        : ''),
    };
    await getAllListData(
      `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.priority}&filters=${encodeURIComponent(
        JSON.stringify(filterInputs),
      )}&page=${count}&asc=${priorityFilterParams?.asc}&sort_by=${priorityFilterParams?.sortby}`,
    ).then((res: any) => {
      setPriorityTableData((pre) => [...pre, ...res.data]);
      setGetMoreLoader(false);
    });
  };
  const completedGetMoreList = async () => {
    setGetMoreLoader(true);
    const count = Number(completedNextToken + 1);
    setCompletedNextToken(count);
    const filterInputs = {
      ...(cookies.get('completed')?.email
        ? { email: ['startswith', cookies.get('completed')?.email] }
        : ''),
      ...(cookies.get('completed')?.source
        ? { source: ['startswith', cookies.get('completed')?.source] }
        : ''),
      ...(cookies.get('completed')?.pages
        ? { pages: ['eq', cookies.get('completed')?.pages] }
        : ''),
      ...(cookies.get('completed')?.assign
        ? { iscompleted_by: ['startswith', cookies.get('completed')?.assign] }
        : ''),
      ...(cookies.get('completed')?.created
        ? {
            created: [
              'between',
              [
                moment.unix(cookies.get('completed')?.created).startOf('day').utc().unix(),
                moment.unix(cookies.get('completed')?.created).endOf('day').utc().unix(),
              ],
            ],
          }
        : ''),
      ...(cookies.get('completed')?.completed
        ? {
            completed_at: [
              'between',
              [
                moment.unix(cookies.get('completed')?.completed).startOf('day').utc().unix(),
                moment.unix(cookies.get('completed')?.completed).endOf('day').utc().unix(),
              ],
            ],
          }
        : ''),
      ...(cookies.get('completed')?.client
        ? { client_code: ['startswith', cookies.get('completed')?.client] }
        : ''),
      ...(cookies.get('completed')?.scac
        ? { scac: ['startswith', cookies.get('completed')?.scac] }
        : ''),
      ...(cookies.get('completed')?.description
        ? { description: ['contains', cookies.get('completed')?.description] }
        : ''),
      ...(cookies.get('completed')?.location
        ? { location: ['startswith', cookies.get('completed')?.location] }
        : ''),
      ...(cookies.get('completed')?.from
        ? { from: ['startswith', cookies.get('completed')?.from] }
        : ''),
    };
    await getAllListData(
      `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.completed}&filters=${encodeURIComponent(
        JSON.stringify(filterInputs),
      )}&page=${count}&asc=${completedFilterParams?.asc}&sort_by=${completedFilterParams?.sortby}`,
    ).then((res: any) => {
      setCompletedTableData((pre) => [...pre, ...res.data]);
      setGetMoreLoader(false);
    });
  };
  const rescanGetMoreList = async () => {
    setGetMoreLoader(true);
    const count = Number(rescanNextToken + 1);
    setRescanNextToken(count);
    const filterInputs = {
      task_status: ['eq', 'pending'],
      display_task: ['eq', true],
      ...(cookies.get('rescan')?.email
        ? { email: ['startswith', cookies.get('rescan')?.email] }
        : ''),
      ...(cookies.get('rescan')?.source
        ? { source: ['startswith', cookies.get('rescan')?.source] }
        : ''),
      ...(cookies.get('rescan')?.pages ? { pages: ['eq', cookies.get('rescan')?.pages] } : ''),
      ...(cookies.get('rescan')?.assign
        ? { task_lock_by: ['startswith', cookies.get('rescan')?.assign] }
        : ''),
      ...(cookies.get('rescan')?.created
        ? {
            created: [
              'between',
              [
                moment.unix(cookies.get('rescan')?.created).startOf('day').utc().unix(),
                moment.unix(cookies.get('rescan')?.created).endOf('day').utc().unix(),
              ],
            ],
          }
        : ''),
      ...(cookies.get('rescan')?.client
        ? { client_code: ['startswith', cookies.get('rescan')?.client] }
        : ''),
      ...(cookies.get('rescan')?.scac ? { scac: ['startswith', cookies.get('rescan')?.scac] } : ''),
      ...(cookies.get('rescan')?.description
        ? { description: ['contains', cookies.get('rescan')?.description] }
        : ''),
      ...(cookies.get('rescan')?.location
        ? { location: ['startswith', cookies.get('rescan')?.location] }
        : ''),
      ...(cookies.get('rescan')?.from ? { from: ['startswith', cookies.get('rescan')?.from] } : ''),
    };
    await getAllListData(
      `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.clone}&filters=${encodeURIComponent(
        JSON.stringify(filterInputs),
      )}&page=${count}&asc=${rescanFilterParams?.asc}&sort_by=${rescanFilterParams?.sortby}`,
    ).then((res: any) => {
      setRescanTableData((pre) => [...pre, ...res.data]);
      setGetMoreLoader(false);
    });
  };
  const iclarifyGetMoreList = async () => {
    setGetMoreLoader(true);
    const count = Number(iclarifyNextToken + 1);
    setIclarifyNextToken(count);
    const filterInputs = {
      task_status: ['eq', 'pending'],
      display_task: ['eq', true],
      ...(cookies.get('iclarify')?.email
        ? { email: ['startswith', cookies.get('iclarify')?.email] }
        : ''),
      ...(cookies.get('iclarify')?.source
        ? { source: ['startswith', cookies.get('iclarify')?.source] }
        : ''),
      ...(cookies.get('iclarify')?.pages ? { pages: ['eq', cookies.get('iclarify')?.pages] } : ''),
      ...(cookies.get('iclarify')?.assign
        ? { task_lock_by: ['startswith', cookies.get('iclarify')?.assign] }
        : ''),
      ...(cookies.get('iclarify')?.created
        ? {
            created: [
              'between',
              [
                moment.unix(cookies.get('iclarify')?.created).startOf('day').utc().unix(),
                moment.unix(cookies.get('iclarify')?.created).endOf('day').utc().unix(),
              ],
            ],
          }
        : ''),
      ...(cookies.get('iclarify')?.client
        ? { client_code: ['startswith', cookies.get('iclarify')?.client] }
        : ''),
      ...(cookies.get('iclarify')?.scac
        ? { scac: ['startswith', cookies.get('iclarify')?.scac] }
        : ''),
      ...(cookies.get('iclarify')?.description
        ? { description: ['contains', cookies.get('iclarify')?.description] }
        : ''),
      ...(cookies.get('iclarify')?.location
        ? { location: ['startswith', cookies.get('iclarify')?.location] }
        : ''),
      ...(cookies.get('iclarify')?.from
        ? { from: ['startswith', cookies.get('iclarify')?.from] }
        : ''),
    };
    await getAllListData(
      `${SETUP_ENDPOINT.list}?table_name=${TABLE_NAME.iclarify}&filters=${encodeURIComponent(
        JSON.stringify(filterInputs),
      )}&page=${count}&asc=${iclarifyFilterParams?.asc}&sort_by=${iclarifyFilterParams?.sortby}`,
    ).then((res: any) => {
      setIclarifyTableData((pre) => [...pre, ...res.data]);
      setGetMoreLoader(false);
    });
  };
  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    // tableDataCount();
    setFilterParams((pre) => ({
      ...pre,
      email: cookies.get('pending')?.email || '',
      source: cookies.get('pending')?.source || '',
      status: cookies.get('pending')?.status || '',
      assign: cookies.get('pending')?.assign || '',
      created: cookies.get('pending')?.created || '',
      client: cookies.get('pending')?.client || '',
      scac: cookies.get('pending')?.scac || '',
      description: cookies.get('pending')?.description || '',
      pages: cookies.get('pending')?.pages || '',
      location: cookies.get('pending')?.location || '',
      from: cookies.get('pending')?.from || '',
    }));
    setCompletedFilterParams((pre) => ({
      ...pre,
      email: cookies.get('completed')?.email || '',
      source: cookies.get('completed')?.source || '',
      status: cookies.get('completed')?.status || '',
      assign: cookies.get('completed')?.assign || '',
      created: cookies.get('completed')?.created || '',
      completed: cookies.get('completed')?.completed || '',
      client: cookies.get('completed')?.client,
      scac: cookies.get('completed')?.scac || '',
      description: cookies.get('completed')?.description || '',
      pages: cookies.get('completed')?.pages || '',
      location: cookies.get('completed')?.location || '',
      from: cookies.get('completed')?.from || '',
    }));
    setRescanFilterParams((pre) => ({
      ...pre,
      email: cookies.get('rescan')?.email || '',
      source: cookies.get('rescan')?.source || '',
      status: cookies.get('rescan')?.status || '',
      assign: cookies.get('rescan')?.assign || '',
      created: cookies.get('rescan')?.created || '',
      client: cookies.get('rescan')?.client || '',
      scac: cookies.get('rescan')?.scac || '',
      description: cookies.get('rescan')?.description || '',
      pages: cookies.get('rescan')?.pages || '',
      location: cookies.get('rescan')?.location || '',
      from: cookies.get('rescan')?.from || '',
    }));
    setPriorityFilterParams((pre) => ({
      ...pre,
      email: cookies.get('priority')?.email || '',
      source: cookies.get('priority')?.source || '',
      status: cookies.get('priority')?.status || '',
      assign: cookies.get('priority')?.assign || '',
      created: cookies.get('priority')?.created || '',
      client: cookies.get('priority')?.client || '',
      scac: cookies.get('priority')?.scac || '',
      description: cookies.get('priority')?.description || '',
      pages: cookies.get('priority')?.pages || '',
      location: cookies.get('priority')?.location || '',
      from: cookies.get('priority')?.from || '',
    }));
    setRescanFilterParams((pre) => ({
      ...pre,
      email: cookies.get('rescan')?.email || '',
      source: cookies.get('rescan')?.source || '',
      status: cookies.get('rescan')?.status || '',
      assign: cookies.get('rescan')?.assign || '',
      created: cookies.get('rescan')?.created || '',
      client: cookies.get('rescan')?.client || '',
      scac: cookies.get('rescan')?.scac || '',
      description: cookies.get('rescan')?.description || '',
      pages: cookies.get('rescan')?.pages || '',
      location: cookies.get('rescan')?.location || '',
      from: cookies.get('rescan')?.from || '',
    }));
    setIclarifyFilterParams((pre) => ({
      ...pre,
      email: cookies.get('iclarify')?.email || '',
      source: cookies.get('iclarify')?.source || '',
      status: cookies.get('iclarify')?.status || '',
      assign: cookies.get('iclarify')?.assign || '',
      created: cookies.get('iclarify')?.created || '',
      client: cookies.get('iclarify')?.client || '',
      scac: cookies.get('iclarify')?.scac || '',
      description: cookies.get('iclarify')?.description || '',
      pages: cookies.get('iclarify')?.pages || '',
      location: cookies.get('iclarify')?.location || '',
      from: cookies.get('iclarify')?.from || '',
    }));
    if (
      _.isEmpty(filterParams) ||
      _.isEmpty(completedFilterParams) ||
      _.isEmpty(priorityFilterParams) ||
      _.isEmpty(rescanFilterParams) ||
      _.isEmpty(iclarifyFilterParams)
    )
      searchFilterData();
  }, []);

  React.useEffect(() => {
    const subscribeData: any = API.graphql<
      GraphQLSubscription<{
        onUpdateItechIndexing_primary: {
          task_status: string;
          display_task: boolean;
          task_lock: boolean;
          signed_email_id: string;
          task_lock_by: string;
        };
      }>
    >(
      graphqlOperation(subscriptions.onUpdateItechIndexing_primary, {
        display_task: true,
        task_lock: true,
      }),
    ).subscribe({
      next: (data) => {
        setPendingTableData((pre) => [
          ...(pre && Array.isArray(pre)
            ? pre.map((e) => {
                if (
                  e.signed_email_id.replace(/-/g, '') ===
                  data?.value?.data?.onUpdateItechIndexing_primary?.signed_email_id
                ) {
                  e.task_lock = data.value?.data?.onUpdateItechIndexing_primary?.task_lock || false;
                  e.task_lock_by =
                    data.value?.data?.onUpdateItechIndexing_primary?.task_lock_by || '';
                }
                return { ...e };
              })
            : []),
        ]);
      },
      error: (error) => {
        console.warn('Subscription error:', error);
      },
    });

    return () => {
      subscribeData.unsubscribe();
    };
  }, []);
  React.useEffect(() => {
    const prioritySubscribeData: any = API.graphql<
      GraphQLSubscription<{
        onUpdateIndexingDashboardPriority: {
          task_status: string;
          task_lock: boolean;
          signed_email_id: string;
          task_lock_by: string;
        };
      }>
    >(
      graphqlOperation(subscriptions.onUpdateIndexingDashboardPriority, {
        task_lock: true,
      }),
    ).subscribe({
      next: (data) => {
        setPriorityTableData((pre) => [
          ...(pre && Array.isArray(pre)
            ? pre.map((e) => {
                if (
                  e.signed_email_id.replace(/-/g, '') ===
                  data?.value?.data?.onUpdateIndexingDashboardPriority?.signed_email_id
                ) {
                  e.task_lock = data.value?.data?.onUpdateIndexingDashboardPriority?.task_lock;
                  e.task_lock_by =
                    data.value?.data?.onUpdateIndexingDashboardPriority?.task_lock_by || '';
                }
                return { ...e };
              })
            : []),
        ]);
      },
      error: (error) => {
        console.warn('Subscription error:', error);
      },
    });
    return () => prioritySubscribeData.unsubscribe();
  }, []);
  React.useEffect(() => {
    const taskCancelSubscribe: any = API.graphql<
      GraphQLSubscription<{
        onUpdateItechIndexing_primary: {
          task_status: string;
          display_task: boolean;
          task_lock: boolean;
          signed_email_id: string;
          task_lock_by: string;
        };
      }>
    >(
      graphqlOperation(subscriptions.onUpdateItechIndexing_primary, {
        display_task: true,
        task_lock: false,
      }),
    ).subscribe({
      next: (data) => {
        if (data?.value?.data?.onUpdateItechIndexing_primary?.task_status === 'completed') {
          setPendingTableData((pre) => [
            ...(pre && Array.isArray(pre)
              ? pre.filter(
                  (e) =>
                    e.signed_email_id.replace(/-/g, '') !==
                    data?.value?.data?.onUpdateItechIndexing_primary?.signed_email_id,
                )
              : []),
          ]);
        } else {
          setPendingTableData((pre) => [
            ...(pre && Array.isArray(pre)
              ? pre.map((e) => {
                  if (
                    e.signed_email_id.replace(/-/g, '') ===
                    data?.value?.data?.onUpdateItechIndexing_primary?.signed_email_id
                  ) {
                    e.task_lock = false;
                    e.task_lock_by = '';
                  }
                  return { ...e };
                })
              : []),
          ]);
        }
      },
      error: (error) => {
        console.warn('Subscription error:', error);
      },
    });
    return () => taskCancelSubscribe.unsubscribe();
  }, []);

  React.useEffect(() => {
    const prioritytaskCancelSubscribe: any = API.graphql<
      GraphQLSubscription<{
        onUpdateIndexingDashboardPriority: {
          task_status: string;
          task_lock: boolean;
          signed_email_id: string;
          task_lock_by: string;
        };
      }>
    >(
      graphqlOperation(subscriptions.onUpdateIndexingDashboardPriority, {
        task_lock: false,
      }),
    ).subscribe({
      next: (data) => {
        if (data?.value?.data?.onUpdateIndexingDashboardPriority?.task_status === 'completed') {
          setPriorityTableData((pre) => {
            return [
              ...(pre && Array.isArray(pre)
                ? pre.filter(
                    (e) =>
                      e.signed_email_id.replace(/-/g, '') !==
                      data?.value?.data?.onUpdateIndexingDashboardPriority?.signed_email_id,
                  )
                : []),
            ];
          });
        } else {
          setPriorityTableData((pre) => [
            ...(pre && Array.isArray(pre)
              ? pre.map((e) => {
                  if (
                    e.signed_email_id.replace(/-/g, '') ===
                    data?.value?.data?.onUpdateIndexingDashboardPriority?.signed_email_id
                  ) {
                    e.task_lock = false;
                    e.task_lock_by = '';
                  }
                  return { ...e };
                })
              : []),
          ]);
        }
      },
      error: (error) => {
        console.warn('Subscription error:', error);
      },
    });
    return () => prioritytaskCancelSubscribe.unsubscribe();
  }, []);

  React.useEffect(() => {
    const subscribeData = API.graphql<
      GraphQLSubscription<{
        onCreateItechIndexing_primary: {
          priority: number;
        };
      }>
    >(
      graphqlOperation(subscriptions.onCreateItechIndexing_primary, {
        // eslint-disable-next-line camelcase
        priority: 5,
      }),
    ).subscribe({
      next: (data) => {
        if (data) {
          setTableCount((pre: any) => ({
            ...pre,
            pendingCount: pre.pendingCount + 1,
          }));
        }
      },
      error: (error) => {
        console.warn('Subscription error:', error);
      },
    });
    return () => subscribeData.unsubscribe();
  }, []);
  React.useEffect(() => {
    const subscribeData = API.graphql<
      GraphQLSubscription<{
        onCreateItechIndexing_primary: {
          priority: number;
        };
      }>
    >(
      graphqlOperation(subscriptions.onCreateIndexingDashboardPriority, {
        // eslint-disable-next-line camelcase
        priority: { eq: 2 },
      }),
    ).subscribe({
      next: (data) => {
        if (data) {
          setTableCount((pre: any) => ({
            ...pre,
            priorityCount: pre.priorityCount + 1,
          }));
        }
      },
      error: (error) => {
        console.warn('Subscription error:', error);
      },
    });
    return () => subscribeData.unsubscribe();
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      topScroll?.current?.scroll({ top: 0, behavior: 'smooth' });
      if (searchParams.get('tabName') === 'pending') setNextToken(1);
      else if (searchParams.get('tabName') === 'completed') setCompletedNextToken(1);
      else if (searchParams.get('tabName') === 'rescan') setRescanNextToken(1);
      else if (searchParams.get('tabName') === 'iclarify') setIclarifyNextToken(1);
      else if (searchParams.get('tabName') === 'priority') setPriorityNextToken(1);
      searchFilterData();
    }, 500);
  }, [
    filterParams,
    completedFilterParams,
    priorityFilterParams,
    rescanFilterParams,
    iclarifyFilterParams,
  ]);
  React.useEffect(() => {
    if (!_.isEmpty(searchParams.get('tabName'))) {
      searchParams.get('tabName')
        ? searchParams.get('tabName')
        : setSearchParams({ tabName: 'pending' });
      if (searchParams.get('tabName') === 'completed') {
        getAllListData(
          `${SETUP_ENDPOINT.list}?table_name=${
            TABLE_NAME.scac
          }&sort_by=scac&filters=${JSON.stringify({
            model_trained: ['eq', 'yes'],
          })}&page_size=500`,
        )
          .then((res) => {
            const scacTrainedList = res?.map((data) => data.scac);
            setModalTrain(scacTrainedList && Array.isArray(scacTrainedList) ? scacTrainedList : []);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      searchFilterData();
      tableDataCount();
    } else {
      setSearchParams({ tabName: 'pending' });
    }
  }, [searchParams.get('tabName')]);
  // React.useEffect(() => {
  //   if (cookies?.get('tabName')) setValue(cookies?.get('tabName'));
  // }, [cookies?.get('tabName')]);
  React.useEffect(() => {
    window?.addEventListener('popstate', () => {
      window?.history.go(1);
    });
  }, [window?.history]);

  const useStyles = makeStyles({
    customTextField: {
      '& input::placeholder': {
        fontSize: '13px',
      },
    },
  });
  const classes = useStyles();
  return (
    <Typography component={'div'}>
      <Header activeIndex={1} />
      <Typography
        component={'div'}
        className='innerbody index-tab'
        sx={{ padding: '10px !important' }}>
        <TabContext value={searchParams.get('tabName') ? searchParams.get('tabName') : 'pending'}>
          <Box
            sx={{
              backgroundColor: '#ffffff',
              borderRadius: '12px 12px 0px 0px',
              display: 'flex',
              height: '53px',
            }}>
            <Typography component={'div'} sx={{ width: '75%' }}>
              <TabList
                ref={tabRef}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                aria-label='lab API tabs example'>
                <Tab label={`Pending (${tableCount?.pendingCount})`} value='pending' />
                <Tab label={`Completed (${tableCount?.completedCount})`} value='completed' />
                <Tab label={`Clarified(${tableCount?.iClarifyCount})`} value='iclarify' />
                {/* <Tab label='Rejected' value='rejected' /> */}
                <Tab label={`Priority (${tableCount?.priorityCount})`} value='priority' />
                <Tab label='Duplicate' value='6' />
                <Tab label={`Rescan (${tableCount?.rescanCount})`} value='rescan' />
              </TabList>
            </Typography>
            <Typography component={'div'} className='d-flex-a flex-end' sx={{ width: '25%' }}>
              <Typography component={'div'} className='result-txt mr-20'>
                Search Results:{' '}
                {searchParams.get('tabName') === 'pending'
                  ? searchCount
                  : searchParams.get('tabName') === 'completed'
                  ? searchCount
                  : searchParams.get('tabName') === 'priority'
                  ? searchCount
                  : searchParams.get('tabName') === 'rescan'
                  ? searchCount
                  : searchParams.get('tabName') === 'iclarify'
                  ? searchCount
                  : 0}
              </Typography>
              <Typography
                component={'div'}
                className='blue-text pr-20 pointer'
                onClick={() => clearFilter()}>
                Clear Filter
              </Typography>
            </Typography>
          </Box>
          <TabPanel value='pending'>
            {searchParams.get('tabName') === 'pending' && (
              <TableContainer
                ref={topScroll}
                className='index-table'
                sx={{ height: 'calc(100vh - 165px)' }}
                id='scrollParent'>
                <InfiniteScroll
                  style={{ overflow: 'unset' }}
                  dataLength={Number(pendingTableData?.length)}
                  next={pendingGetMoreList}
                  hasMore={true}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <Table aria-label='collapsible table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className='w-15'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Email/FTP File Name</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('email', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('email', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography> Source</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('source', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('source', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Status</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('task_status', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('task_status', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Assign</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('task_lock_by', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('task_lock_by', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Created Date</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('created', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('created', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <TextOnlyTooltip arrow title={'Client Code'}>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography>Client..</Typography>
                              <Typography className='p-relative'>
                                <span
                                  className='upArrow sortIcon pointer'
                                  onClick={() => sortTableData('client_code', true)}>
                                  ▲
                                </span>
                                <span
                                  className='downArrow sortIcon pointer'
                                  onClick={() => sortTableData('client_code', false)}>
                                  ▼
                                </span>
                              </Typography>
                            </Typography>
                          </TextOnlyTooltip>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Scac</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('scac', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('scac', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Description</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('description', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('description', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Pages</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('pages', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('pages', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <Tooltip title='Site Code/Client Location' placement='top'>
                          <TableCell className='w-8'>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography>Location</Typography>
                              <Typography className='p-relative'>
                                <span
                                  className='upArrow sortIcon pointer'
                                  onClick={() => sortTableData('location', true)}>
                                  ▲
                                </span>
                                <span
                                  className='downArrow sortIcon pointer'
                                  onClick={() => sortTableData('location', false)}>
                                  ▼
                                </span>
                              </Typography>
                            </Typography>
                          </TableCell>
                        </Tooltip>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>From</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('from', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('from', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        {/* <TableCell className='w-15'>More</TableCell> */}
                      </TableRow>
                      <TableRow className='back-color'>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Email'
                              // variant='outlined'
                              placeholder='Email'
                              value={filterParams?.email}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                                setCookies('email', e.target.value);
                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              // label='Search Source'
                              // variant='outlined'
                              classes={{ root: classes.customTextField }}
                              placeholder='Source'
                              value={filterParams.source}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  source: searchRegexCheck(e.target.value),
                                }));
                                setCookies('source', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              // label='Search Status'
                              // variant='outlined'
                              classes={{ root: classes.customTextField }}
                              placeholder='Status'
                              value={filterParams.status}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  status: searchRegexCheck(e.target.value),
                                }));
                                setCookies('status', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Assign'
                              // variant='outlined'
                              placeholder='Assign'
                              value={filterParams.assign}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  assign: searchRegexCheck(e.target.value),
                                }));
                                setCookies('assign', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                value={
                                  filterParams?.created
                                    ? dayjs(moment.unix(filterParams?.created).format('L'))
                                    : null
                                }
                                disableFuture
                                format='DD/MM/YYYY'
                                onChange={(e) => {
                                  const date = moment(e.toDate()).format('L');
                                  setFilterParams((prev: any) => ({
                                    ...prev,
                                    created: date ? moment(date).unix() : null,
                                  }));
                                  setCookies('created', date ? moment(date).unix() : null);
                                }}
                              />
                            </LocalizationProvider>
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              placeholder='Client Code'
                              // label='Search Client'
                              // variant='outlined'
                              value={filterParams.client}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  client: searchRegexCheck(e.target.value),
                                }));
                                setCookies('client', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search SCAC'
                              // variant='outlined'
                              placeholder='Scac'
                              value={filterParams.scac}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  scac: searchRegexCheck(e.target.value),
                                }));

                                setCookies('scac', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Description'
                              // variant='outlined'
                              placeholder='Description'
                              value={filterParams.description}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  description: e.target.value,
                                }));
                                setCookies('description', e.target.value);
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Pages'
                              // variant='outlined'
                              placeholder='Pages'
                              value={filterParams.pages}
                              // sx={{ width: '50px' }}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  pages: searchRegexCheck(e.target.value),
                                }));
                                setCookies('pages', searchRegexCheck(e.target.value));

                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Location'
                              // variant='outlined'
                              placeholder='Location'
                              value={filterParams.location}
                              // sx={{ width: '50px' }}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  location: searchRegexCheck(e.target.value),
                                }));
                                setCookies('location', searchRegexCheck(e.target.value));

                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search From'
                              // variant='outlined'
                              placeholder='From'
                              value={filterParams.from}
                              sx={{ minwidth: '20px !important' }}
                              onChange={(e) => {
                                setFilterParams((prev: any) => ({
                                  ...prev,
                                  from: searchRegexCheck(e.target.value),
                                }));
                                setCookies('from', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        {/* <TableCell>
                      <Typography component={'div'} className='d-flex-a' />
                    </TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {loader ? (
                        <Loader openLoader={loader} />
                      ) : searchParams.get('tabName') === 'pending' &&
                        pendingTableData?.length > 0 ? (
                        pendingTableData?.map((row: any, index: number) => (
                          <React.Fragment key={index}>
                            <TableRow
                              onClick={() =>
                                row.task_lock === false && navDocumentView(row, 'pending', index)
                              }
                              sx={{
                                '& > *': {
                                  cursor: row.task_lock ? 'not-allowed' : 'pointer',
                                  backgroundColor: moment().isAfter(
                                    moment.unix(row?.created).add(12, 'hours'),
                                  )
                                    ? index % 2
                                      ? 'rgb(250 237 223) !important'
                                      : 'rgb(252 243 234) !important'
                                    : index % 2
                                    ? '#fafffb'
                                    : '',
                                },
                              }}>
                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.email, 'Email/FTP File Name')
                                }>
                                <Typography component={'div'} className='d-flex-a'>
                                  {' '}
                                  {/* <Checkbox /> */}
                                  <Typography
                                    sx={{
                                      fontFamily: 'Inter SemiBold !important',
                                      fontSize: '14px !important',
                                      lineHeight: '17px !important',
                                    }}>
                                    <RightMenu
                                      menuId={`${MENU_ID}`}
                                      copy={`copy${index + 1}`}
                                      data={pendingTableData}
                                    />
                                    {row?.email}
                                    {row.task_lock && (
                                      <span
                                        onClick={() => handleOnclickLock(row)}
                                        className='pointer'>
                                        <LockPersonIcon
                                          fontSize='small'
                                          sx={{ fill: 'red !important', marginLeft: '5px' }}
                                        />
                                      </span>
                                    )}
                                  </Typography>{' '}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) => {
                                  handleContextMenu(e, row, row?.source || '', 'Source');
                                }}>
                                {row?.source}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    row?.task_status.charAt(0).toUpperCase() +
                                      row?.task_status.slice(1) || '',
                                    'Status',
                                  )
                                }>
                                {row?.task_status.charAt(0).toUpperCase() +
                                  row?.task_status.slice(1) || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    row?.task_lock_by.charAt(0).toUpperCase() +
                                      row?.task_lock_by.slice(1) || '',
                                    'Assign',
                                  )
                                }>
                                {row?.task_lock_by.charAt(0).toUpperCase() +
                                  row?.task_lock_by.slice(1) || '--'}
                              </TableCell>

                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '',
                                    'Created',
                                  )
                                }>
                                <TextOnlyTooltip
                                  arrow
                                  title={
                                    moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'
                                  }>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.client_code || '', 'Client Code')
                                }>
                                {row?.client_code || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.scac || '', 'SCAC Code')
                                }>
                                {row?.scac || '--'}
                              </TableCell>
                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.description || '', 'Description')
                                }>
                                <TextOnlyTooltip arrow title={row?.description}>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {row?.description || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.pages || '', 'Pages')
                                }>
                                {row?.pages || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row.location || '', 'Location')
                                }>
                                {row.location || '--'}
                              </TableCell>
                              <TableCell
                                onContextMenu={(e) => handleContextMenu(e, row, row?.from, 'From')}>
                                <TextOnlyTooltip arrow title={row?.from}>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {row?.from || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      ) : (
                        !getMoreLoader && (
                          <React.Fragment>
                            <tr style={{ textAlign: 'center', height: '20vh' }}>
                              <td colSpan={12}>
                                <h2 style={{ color: 'grey' }}>No Data Found</h2>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      )}
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel value='completed'>
            {/* <Typography>
              <DataTable
                data={[
                  'Email/FTP File Name',
                  'Source',
                  'Status',
                  'Assign',
                  'Created',
                  'Client',
                  'SCAC',
                  'Description',
                ]}
                label={[
                  'email',
                  'source',
                  'status',
                  'assign',
                  'created',
                  'client',
                  'scac',
                  'description',
                ]}
                tableBody={[
                  [
                    'priority@data1',
                    'body1',
                    'body1',
                    'body1',
                    'priority@data1',
                    'body1',
                    'body1',
                    'body1',
                  ],
                  ['priority@data2', 'body2', 'body2', 'body2'],
                ]}
              />
            </Typography> */}
            <TableContainer
              className='index-table'
              ref={topScroll}
              id='scrollParent1'
              style={{
                height: 'calc(100vh - 165px)',
                overflow: 'auto',
              }}>
              <InfiniteScroll
                style={{ overflow: 'unset' }}
                dataLength={Number(completedTableData?.length)}
                pullDownToRefreshThreshold={50}
                next={completedGetMoreList}
                hasMore={true}
                scrollableTarget={'scrollParent1'}
                loader={
                  getMoreLoader && (
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        textAlign: 'center',
                        width: '100%',
                      }}>
                      <CircularProgress
                        size={30}
                        sx={{
                          color: '#002c69',
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  )
                }
                endMessage>
                <Table aria-label='collapsible table' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='w-15'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Email/FTP File Name</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('email', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('email', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography> Source</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('source', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('source', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Status</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('task_status', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('task_status', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Assign</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('iscompleted_by', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('iscompleted_by', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Created Date</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('created', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('created', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Completed Date</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('completed_at', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('completed_at', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <TextOnlyTooltip arrow title={'Client Code'}>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Client..</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('client_code', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('client_code', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TextOnlyTooltip>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Scac</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('scac', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('scac', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Description</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('description', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('description', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Pages</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('pages', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('pages', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <Tooltip title='Site Code/Client Location' placement='top'>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Location</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('location', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('location', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                      </Tooltip>
                      <TableCell sx={{ width: '5%' }}>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>From</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('from', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('from', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: '3%' }}>More</TableCell>
                    </TableRow>
                    <TableRow className='back-color'>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Email'
                            // variant='outlined'
                            placeholder='Email'
                            value={completedFilterParams?.email}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                email: e.target.value,
                              }));
                              setCompletedCookies('email', e.target.value);
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Source'
                            // variant='outlined'
                            placeholder='Source'
                            value={completedFilterParams.source}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                source: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('source', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Status'
                            // variant='outlined'
                            placeholder='Status'
                            value={completedFilterParams.status}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                status: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('status', searchRegexCheck(e.target.value));
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Assign'
                            // variant='outlined'
                            placeholder='Assign'
                            value={completedFilterParams.assign}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                assign: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('assign', searchRegexCheck(e.target.value));
                            }}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              value={
                                completedFilterParams?.created
                                  ? dayjs(moment.unix(completedFilterParams?.created).format('L'))
                                  : null
                              }
                              disableFuture
                              format='DD/MM/YYYY'
                              onChange={(e) => {
                                const date = moment(e.toDate()).format('L');
                                setCompletedFilterParams((prev: any) => ({
                                  ...prev,
                                  created: date ? moment(date).unix() : null,
                                }));
                                setCompletedCookies('created', date ? moment(date).unix() : null);
                              }}
                            />
                          </LocalizationProvider>
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              value={
                                completedFilterParams?.completed
                                  ? dayjs(moment.unix(completedFilterParams?.completed).format('L'))
                                  : null
                              }
                              disableFuture
                              format='DD/MM/YYYY'
                              onChange={(e) => {
                                const date = moment(e.toDate()).format('L');
                                setCompletedFilterParams((prev: any) => ({
                                  ...prev,
                                  completed: date ? moment(date).unix() : null,
                                }));
                                setCompletedCookies('completed', date ? moment(date).unix() : null);
                              }}
                            />
                          </LocalizationProvider>
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Client'
                            // variant='outlined'
                            placeholder='Client Code'
                            value={completedFilterParams.client}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                client: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('client', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            placeholder='Scac'
                            // label='Search SCAC'
                            // variant='outlined'
                            value={completedFilterParams.scac}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                scac: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('scac', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Description'
                            // variant='outlined'
                            placeholder='Description'
                            value={completedFilterParams.description}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                description: e.target.value,
                              }));
                              setCompletedCookies('description', e.target.value);
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Pages'
                            // variant='outlined'
                            placeholder='Pages'
                            value={completedFilterParams.pages}
                            // sx={{ width: '50px' }}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                pages: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('pages', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Location'
                            // variant='outlined'
                            placeholder='Location'
                            value={completedFilterParams.location}
                            // sx={{ width: '50px' }}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                location: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('location', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search From'
                            // variant='outlined'
                            placeholder='From'
                            value={completedFilterParams.from}
                            // sx={{ width: '50px' }}
                            onChange={(e) => {
                              setCompletedFilterParams((prev: any) => ({
                                ...prev,
                                from: searchRegexCheck(e.target.value),
                              }));
                              setCompletedCookies('from', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a' />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loader ? (
                      <Loader openLoader={loader} />
                    ) : searchParams.get('tabName') === 'completed' &&
                      completedTableData?.length > 0 ? (
                      completedTableData?.map((row: any, index: number) => (
                        <>
                          <TableRow
                            className='pointer'
                            onClick={() => navDocumentView(row, 'completed')}
                            key={index}
                            sx={{
                              backgroundColor:
                                row.clone_status === 'yes'
                                  ? '#ffffb7!important'
                                  : index % 2 && '#fafffb!important',
                            }}>
                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.email, 'Email/FTP File Name')
                              }>
                              <Typography component={'div'} className='d-flex-a'>
                                {' '}
                                {/* <Checkbox /> */}
                                <Typography className='d-flex-a mail-txt'>
                                  {row?.batched_result !== '' && (
                                    <Typography component={'div'} className='syncbox'>
                                      <Typography
                                        component={'span'}
                                        className={row?.ml_result ? 'mlblink' : 'blink'}
                                      />
                                    </Typography>
                                  )}
                                  {row?.email}{' '}
                                  <RightMenu
                                    menuId={`${MENU_ID}`}
                                    copy={'copy'}
                                    data={completedTableData}
                                  />
                                </Typography>{' '}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.source || '', 'Source')
                              }>
                              {row?.source}
                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  row,
                                  row?.task_status.charAt(0).toUpperCase() +
                                    row?.task_status.slice(1) || '',
                                  'Status',
                                )
                              }>
                              {row?.task_status.charAt(0).toUpperCase() +
                                row?.task_status.slice(1) || '--'}
                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  row,
                                  row?.iscompleted_by.charAt(0).toUpperCase() +
                                    row?.iscompleted_by.slice(1) || '',
                                  'Assigned To',
                                )
                              }>
                              {row?.iscompleted_by.charAt(0).toUpperCase() +
                                row?.iscompleted_by.slice(1) || '--'}
                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>

                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  row,
                                  moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '',
                                  'Created',
                                )
                              }>
                              <TextOnlyTooltip
                                arrow
                                title={
                                  moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'
                                }>
                                <div
                                  style={{
                                    width: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}>
                                  {moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'}
                                </div>
                              </TextOnlyTooltip>
                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>
                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.completed_at || '', 'Completed Date')
                              }>
                              <TextOnlyTooltip
                                arrow
                                title={
                                  moment.unix(row?.completed_at).format('DD-MM-YYYY hh:mm a') ||
                                  '--'
                                }>
                                <div
                                  style={{
                                    width: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}>
                                  {moment.unix(row?.completed_at).format('DD-MM-YYYY hh:mm a') ||
                                    '--'}
                                </div>
                              </TextOnlyTooltip>
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.client_code || '', 'Client Code')
                              }>
                              {(isJsonString(row?.task_info) && row?.client_code) || '--'}
                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>

                            <TableCell
                              sx={{
                                fontWeight: 'bold',
                                color: modelTrain.includes(row?.scac) ? 'green' : 'red',
                              }}
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.scac || '', 'SCAC Code')
                              }>
                              {(isJsonString(row?.task_info) && row?.scac) || '--'}
                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>
                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.description || '', 'Description')
                              }>
                              <TextOnlyTooltip arrow title={row?.description}>
                                <div
                                  style={{
                                    width: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}>
                                  {row?.description || '--'}
                                </div>
                              </TextOnlyTooltip>
                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.pages || '', 'Pages')
                              }>
                              {row?.pages || '--'}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.location || '', 'Location')
                              }>
                              {row?.location || '--'}
                            </TableCell>
                            <TableCell
                              onContextMenu={(e) => handleContextMenu(e, row, row?.from, 'From')}>
                              <TextOnlyTooltip arrow title={row?.from || '--'}>
                                <div
                                  style={{
                                    width: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}>
                                  {row?.from || '--'}
                                </div>
                              </TextOnlyTooltip>

                              {/* <RightMenu menuId={`${MENU_ID}`} copy={`copy${index + 1}`} /> */}
                            </TableCell>
                            <TableCell onClick={(e) => e.stopPropagation()}>
                              <Typography component={'div'} onClick={() => toggleAcordion(index)}>
                                {expandInfo[index] ? (
                                  <Typography component={'div'} className='d-flex-a pointer'>
                                    <KeyboardArrowUpIcon />
                                    <Typography>More</Typography>{' '}
                                  </Typography>
                                ) : (
                                  <Typography component={'div'} className='d-flex-a pointer'>
                                    <KeyboardArrowDownIcon />
                                    <Typography>More</Typography>
                                  </Typography>
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                              }}
                              colSpan={12}>
                              <Collapse in={expandInfo[index]} timeout='auto' unmountOnExit>
                                {row?.batched_result !== '' && JSON.parse(row?.batched_result)
                                  ? JSON.parse(row?.batched_result).map(
                                      (data: any, index: number) => {
                                        return (
                                          <Box sx={{ margin: 1 }} key={index}>
                                            <Typography
                                              sx={{
                                                backgroundColor: JSON.parse(
                                                  row?.task_info,
                                                ).clone_info?.includes(index)
                                                  ? '#ffffb7!important'
                                                  : '',
                                              }}
                                              component={'div'}
                                              className='inner-table-row d-flex-a'>
                                              <Grid container>
                                                <Grid md={3} sm={12}>
                                                  <Typography className='inner-title'>
                                                    Batch Name
                                                  </Typography>
                                                  <Typography>{data?.batchName || '--'}</Typography>
                                                </Grid>
                                                <Grid md={3} sm={12}>
                                                  <Typography className='inner-title d-flex-a'>
                                                    File Name
                                                  </Typography>
                                                  <Typography>
                                                    <TextOnlyTooltip
                                                      arrow
                                                      title={
                                                        !_.isEmpty(row?.file_source)
                                                          ? JSON.parse(JSON.parse(row?.file_source))
                                                              ?.email_meta_data_path?.split('/')
                                                              ?.pop()
                                                          : '--'
                                                      }>
                                                      <div
                                                        style={{
                                                          width: 300,
                                                          overflow: 'hidden',
                                                          textOverflow: 'ellipsis',
                                                          whiteSpace: 'nowrap',
                                                        }}>
                                                        {!_.isEmpty(row?.file_source)
                                                          ? JSON.parse(JSON.parse(row?.file_source))
                                                              ?.email_meta_data_path?.split('/')
                                                              ?.pop()
                                                          : '--'}
                                                      </div>
                                                    </TextOnlyTooltip>
                                                  </Typography>
                                                </Grid>
                                                <Grid md={1} sm={12}>
                                                  <Typography className='inner-title'>
                                                    Image ID
                                                  </Typography>
                                                  <Typography>{data?.imageId || '--'}</Typography>
                                                </Grid>
                                                <Grid
                                                  md={4.5}
                                                  sm={12}
                                                  className='d-flex-a flex-end'>
                                                  <Typography
                                                    component={'div'}
                                                    className='d-flex-a'>
                                                    <Button
                                                      className='download-btn mr-20'
                                                      id='demo-customized-button'
                                                      aria-controls={
                                                        open ? 'demo-customized-menu' : undefined
                                                      }
                                                      aria-haspopup='true'
                                                      aria-expanded={open ? 'true' : undefined}
                                                      disableElevation
                                                      onClick={(e) =>
                                                        menuOpen(e, data, row?.ml_result, index)
                                                      }
                                                      endIcon={<KeyboardArrowDownIcon />}>
                                                      {' '}
                                                      <img
                                                        src={DownloadIcon}
                                                        alt='download'
                                                        className='mr-7'
                                                      />{' '}
                                                      Download
                                                    </Button>
                                                    <StyledMenu
                                                      sx={{ left: '30px' }}
                                                      id='demo-customized-menu'
                                                      MenuListProps={{
                                                        'aria-labelledby': 'demo-customized-button',
                                                      }}
                                                      open={open}
                                                      anchorEl={anchorEl}
                                                      onClose={menuClose}>
                                                      <MenuItem
                                                        onClick={() => {
                                                          window.open(
                                                            encodeUriTourl(
                                                              JSON.parse(
                                                                JSON.parse(row?.file_source),
                                                              ).raw_pdf_path
                                                                ? JSON.parse(
                                                                    JSON.parse(row?.file_source),
                                                                  ).raw_pdf_path
                                                                : JSON.parse(
                                                                    JSON.parse(row?.file_source),
                                                                  ).attachment,
                                                            ),
                                                            '_blank',
                                                          );
                                                          menuClose();
                                                        }}
                                                        disableRipple>
                                                        Input File
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={() => {
                                                          window.open(
                                                            uriTourl(tiffFile?.url),
                                                            '_blank',
                                                          );
                                                          menuClose();
                                                        }}
                                                        disableRipple>
                                                        Tiff
                                                      </MenuItem>
                                                      {isJsonString(row?.ml_result) &&
                                                        JSON.parse(row?.ml_result) !== null && (
                                                          <MenuItem
                                                            onClick={() => {
                                                              if (
                                                                JSON.parse(row?.ml_result) !== null
                                                              ) {
                                                                downloadJsonAsFile(
                                                                  downloadMlresult?.mlRes
                                                                    ? downloadMlresult.mlRes
                                                                    : 'No Invoice Found',
                                                                  downloadMlresult?.mlResFileName,
                                                                );
                                                              }
                                                              menuClose();
                                                            }}
                                                            disableRipple>
                                                            Json
                                                          </MenuItem>
                                                        )}
                                                    </StyledMenu>

                                                    <Button
                                                      className='clonedoc-btn mr-20'
                                                      id='demo-customized-button'
                                                      aria-controls={
                                                        open ? 'demo-customized-menu' : undefined
                                                      }
                                                      aria-haspopup='true'
                                                      aria-expanded={open ? 'true' : undefined}
                                                      disableElevation
                                                      onClick={(e) => DocMenuOpen(e, index)}
                                                      endIcon={<KeyboardArrowDownIcon />}>
                                                      {' '}
                                                      <img
                                                        src={Clonedoc}
                                                        alt='clone'
                                                        className='mr-7'
                                                      />{' '}
                                                      Clone
                                                    </Button>
                                                    <StyledMenu
                                                      sx={{ left: '30px' }}
                                                      id='demo-customized-menu'
                                                      MenuListProps={{
                                                        'aria-labelledby': 'demo-customized-button',
                                                      }}
                                                      open={DocOpen}
                                                      anchorEl={cloneDoc}
                                                      onClose={DocMenuClose}>
                                                      <MenuItem
                                                        onClick={() => {
                                                          cloneByFull(row?.signed_email_id);
                                                        }}>
                                                        Document
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={() => {
                                                          cloneByImg(row?.signed_email_id);
                                                        }}>
                                                        Image
                                                      </MenuItem>
                                                    </StyledMenu>
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Typography>
                                          </Box>
                                        );
                                      },
                                    )
                                  : !completedTableData.length && (
                                      <Box sx={{ margin: 1 }}>
                                        <Typography
                                          component={'div'}
                                          className='inner-table-row d-flex-ja'
                                          sx={{ paddingTop: '10px' }}>
                                          <h3 style={{ color: 'grey' }}>No Records Found</h3>
                                        </Typography>
                                      </Box>
                                    )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    ) : (
                      !getMoreLoader && (
                        <React.Fragment>
                          <tr style={{ textAlign: 'center', height: '20vh' }}>
                            <td colSpan={12}>
                              <h2 style={{ color: 'grey' }}>No Data Found</h2>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </TableContainer>
          </TabPanel>
          <TabPanel value='iclarify'>
            {searchParams.get('tabName') === 'iclarify' && (
              <TableContainer
                ref={topScroll}
                className='index-table'
                sx={{ height: 'calc(100vh - 165px)' }}
                id='scrollParent'>
                <InfiniteScroll
                  style={{ overflow: 'unset' }}
                  dataLength={Number(iclarifyTableData?.length)}
                  next={iclarifyGetMoreList}
                  hasMore={true}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <Table aria-label='collapsible table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className='w-15'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Email/FTP File Name</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('email', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('email', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography> Source</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('source', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('source', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Status</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('task_status', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('task_status', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Assign</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('task_lock_by', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('task_lock_by', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Created Date</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('created', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('created', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <TextOnlyTooltip arrow title={'Client Code'}>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography>Client..</Typography>
                              <Typography className='p-relative'>
                                <span
                                  className='upArrow sortIcon pointer'
                                  onClick={() => sortTableData('client_code', true)}>
                                  ▲
                                </span>
                                <span
                                  className='downArrow sortIcon pointer'
                                  onClick={() => sortTableData('client_code', false)}>
                                  ▼
                                </span>
                              </Typography>
                            </Typography>
                          </TextOnlyTooltip>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Scac</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('scac', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('scac', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Description</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('description', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('description', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Pages</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('pages', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('pages', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <Tooltip title='Site Code/Client Location' placement='top'>
                          <TableCell className='w-8'>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography>Location</Typography>
                              <Typography className='p-relative'>
                                <span
                                  className='upArrow sortIcon pointer'
                                  onClick={() => sortTableData('location', true)}>
                                  ▲
                                </span>
                                <span
                                  className='downArrow sortIcon pointer'
                                  onClick={() => sortTableData('location', false)}>
                                  ▼
                                </span>
                              </Typography>
                            </Typography>
                          </TableCell>
                        </Tooltip>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>From</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('from', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('from', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        {/* <TableCell className='w-15'>More</TableCell> */}
                      </TableRow>
                      <TableRow className='back-color'>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Email'
                              // variant='outlined'
                              placeholder='Email'
                              value={iclarifyFilterParams?.email}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                                setIclarifyCookies('email', e.target.value);
                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              // label='Search Source'
                              // variant='outlined'
                              classes={{ root: classes.customTextField }}
                              placeholder='Source'
                              value={iclarifyFilterParams.source}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  source: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('source', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              // label='Search Status'
                              // variant='outlined'
                              classes={{ root: classes.customTextField }}
                              placeholder='Status'
                              value={iclarifyFilterParams.status}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  status: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('status', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Assign'
                              // variant='outlined'
                              placeholder='Assign'
                              value={iclarifyFilterParams.assign}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  assign: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('assign', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                value={
                                  iclarifyFilterParams?.created
                                    ? dayjs(moment.unix(iclarifyFilterParams?.created).format('L'))
                                    : null
                                }
                                disableFuture
                                format='DD/MM/YYYY'
                                onChange={(e) => {
                                  const date = moment(e.toDate()).format('L');
                                  setIclarifyFilterParams((prev: any) => ({
                                    ...prev,
                                    created: date ? moment(date).unix() : null,
                                  }));
                                  setIclarifyCookies('created', date ? moment(date).unix() : null);
                                }}
                              />
                            </LocalizationProvider>
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              placeholder='Client Code'
                              // label='Search Client'
                              // variant='outlined'
                              value={iclarifyFilterParams.client}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  client: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('client', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search SCAC'
                              // variant='outlined'
                              placeholder='Scac'
                              value={iclarifyFilterParams.scac}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  scac: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('scac', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Description'
                              // variant='outlined'
                              placeholder='Description'
                              value={iclarifyFilterParams.description}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  description: e.target.value,
                                }));
                                setIclarifyCookies('description', e.target.value);
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Pages'
                              // variant='outlined'
                              placeholder='Pages'
                              value={iclarifyFilterParams.pages}
                              // sx={{ width: '50px' }}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  pages: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('pages', searchRegexCheck(e.target.value));

                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Location'
                              // variant='outlined'
                              placeholder='Location'
                              value={iclarifyFilterParams.location}
                              // sx={{ width: '50px' }}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  location: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('location', searchRegexCheck(e.target.value));

                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search From'
                              // variant='outlined'
                              placeholder='From'
                              value={iclarifyFilterParams.from}
                              sx={{ minwidth: '20px !important' }}
                              onChange={(e) => {
                                setIclarifyFilterParams((prev: any) => ({
                                  ...prev,
                                  from: searchRegexCheck(e.target.value),
                                }));
                                setIclarifyCookies('from', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        {/* <TableCell>
                      <Typography component={'div'} className='d-flex-a' />
                    </TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {loader ? (
                        <Loader openLoader={loader} />
                      ) : searchParams.get('tabName') === 'iclarify' &&
                        iclarifyTableData?.length > 0 ? (
                        iclarifyTableData?.map((row: any, index: number) => (
                          <React.Fragment key={index}>
                            <TableRow
                              className='pointer'
                              onClick={() =>
                                row.task_lock === false && navDocumentView(row, 'iclarify', index)
                              }
                              sx={{
                                backgroundColor: index % 2 && '#fafffb',
                              }}>
                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.email, 'Email/FTP File Name')
                                }>
                                <Typography component={'div'} className='d-flex-a'>
                                  {' '}
                                  {/* <Checkbox /> */}
                                  <Typography
                                    sx={{
                                      fontFamily: 'Inter SemiBold !important',
                                      fontSize: '14px !important',
                                      lineHeight: '17px !important',
                                    }}>
                                    <RightMenu
                                      menuId={`${MENU_ID}`}
                                      copy={`copy${index + 1}`}
                                      data={pendingTableData}
                                    />
                                    {row?.email}
                                    {row.task_lock && (
                                      <span
                                        onClick={() => handleOnclickLock(row)}
                                        className='pointer'>
                                        <LockPersonIcon
                                          fontSize='small'
                                          sx={{ fill: 'red !important', marginLeft: '5px' }}
                                        />
                                      </span>
                                    )}
                                  </Typography>{' '}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) => {
                                  handleContextMenu(e, row, row?.source || '', 'Source');
                                }}>
                                {row?.source}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    row?.task_status.charAt(0).toUpperCase() +
                                      row?.task_status.slice(1) || '--',
                                    'Status',
                                  )
                                }>
                                {row?.task_status.charAt(0).toUpperCase() +
                                  row?.task_status.slice(1) || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    row?.clarification_indexer || '--',
                                    'Assign',
                                  )
                                }>
                                {row?.clarification_indexer || '--'}
                              </TableCell>

                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '',
                                    'Created',
                                  )
                                }>
                                <TextOnlyTooltip
                                  arrow
                                  title={
                                    moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'
                                  }>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.client_code || '', 'Client Code')
                                }>
                                {row?.client_code || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.scac || '', 'SCAC Code')
                                }>
                                {row?.scac || '--'}
                              </TableCell>
                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.description || '', 'Description')
                                }>
                                <TextOnlyTooltip arrow title={row?.description}>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {row?.description || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.pages || '', 'Pages')
                                }>
                                {row?.pages || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row.location || '', 'Location')
                                }>
                                {row.location || '--'}
                              </TableCell>
                              <TableCell
                                onContextMenu={(e) => handleContextMenu(e, row, row?.from, 'From')}>
                                <TextOnlyTooltip arrow title={row?.from}>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {row?.from || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      ) : (
                        !getMoreLoader && (
                          <React.Fragment>
                            <tr style={{ textAlign: 'center', height: '20vh' }}>
                              <td colSpan={12}>
                                <h2 style={{ color: 'grey' }}>No Data Found</h2>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      )}
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel value='4'></TabPanel>

          <TabPanel value='priority'>
            <TableContainer
              ref={topScroll}
              className='index-table'
              sx={{ height: 'calc(100vh - 165px)' }}
              id='scrollParentPriority'>
              <InfiniteScroll
                style={{ overflow: 'unset' }}
                dataLength={Number(priorityTableData?.length)}
                next={priorityGetMoreList}
                hasMore={true}
                scrollableTarget={'scrollParentPriority'}
                loader={
                  getMoreLoader && (
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        textAlign: 'center',
                        width: '100%',
                      }}>
                      <CircularProgress
                        size={30}
                        sx={{
                          color: '#002c69',
                          marginBottom: 10,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  )
                }
                endMessage>
                <Table aria-label='collapsible table' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='w-15'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Email/FTP File Name</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('email', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('email', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography> Source</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('source', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('source', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Status</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('task_status', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('task_status', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Assign</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('task_lock_by', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('task_lock_by', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Created Date</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('created', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('created', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <TextOnlyTooltip arrow title={'Client Code'}>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Client..</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('client_code', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('client_code', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TextOnlyTooltip>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Scac</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('scac', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('scac', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Description</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('description', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('description', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>Pages</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('pages', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('pages', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      <Tooltip title='Site Code/Client Location' placement='top'>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Location</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('location', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('location', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                      </Tooltip>
                      <TableCell className='w-8'>
                        <Typography component={'div'} className='d-flex-a'>
                          <Typography>From</Typography>
                          <Typography className='p-relative'>
                            <span
                              className='upArrow sortIcon pointer'
                              onClick={() => sortTableData('from', true)}>
                              ▲
                            </span>
                            <span
                              className='downArrow sortIcon pointer'
                              onClick={() => sortTableData('from', false)}>
                              ▼
                            </span>
                          </Typography>
                        </Typography>
                      </TableCell>
                      {/* <TableCell className='w-15'>More</TableCell> */}
                    </TableRow>
                    <TableRow className='back-color'>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Email'
                            // variant='outlined'
                            placeholder='Email'
                            value={priorityFilterParams?.email}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                email: e.target.value,
                              }));
                              setPriorityCookies('email', e.target.value);
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Source'
                            // variant='outlined'
                            placeholder='Source'
                            value={priorityFilterParams.source}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                source: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('source', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Status'
                            // variant='outlined'
                            placeholder='Status'
                            value={priorityFilterParams.status}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                status: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('status', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Assign'
                            // variant='outlined'
                            placeholder='Assign'
                            value={priorityFilterParams.assign}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                assign: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('assign', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              value={
                                priorityFilterParams?.created
                                  ? dayjs(moment.unix(priorityFilterParams?.created).format('L'))
                                  : null
                              }
                              disableFuture
                              format='DD/MM/YYYY'
                              onChange={(e) => {
                                const date = moment(e.toDate()).format('L');
                                setPriorityFilterParams((prev: any) => ({
                                  ...prev,
                                  created: date ? moment(date).unix() : null,
                                }));
                                setPriorityCookies('created', date ? moment(date).unix() : null);
                              }}
                            />
                          </LocalizationProvider>
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Client'
                            // variant='outlined'
                            placeholder='Client'
                            value={priorityFilterParams.client}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                client: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('client', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search SCAC'
                            // variant='outlined'
                            placeholder='Scac'
                            value={priorityFilterParams.scac}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                scac: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('scac', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Description'
                            // variant='outlined'
                            placeholder='Description'
                            value={priorityFilterParams.description}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                description: e.target.value,
                              }));
                              setPriorityCookies('description', e.target.value);
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Pages'
                            // variant='outlined'
                            placeholder='Pages'
                            value={priorityFilterParams.pages}
                            sx={{ width: '50px' }}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                pages: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('pages', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search Location'
                            // variant='outlined'
                            placeholder='Location'
                            value={priorityFilterParams.location}
                            sx={{ width: '50px' }}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                location: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('location', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'div'} className='d-flex-a'>
                          <TextField
                            classes={{ root: classes.customTextField }}
                            // label='Search From'
                            // variant='outlined'
                            placeholder='From'
                            value={priorityFilterParams.from}
                            // sx={{ width: '50px' }}
                            onChange={(e) => {
                              setPriorityFilterParams((prev: any) => ({
                                ...prev,
                                from: searchRegexCheck(e.target.value),
                              }));
                              setPriorityCookies('from', searchRegexCheck(e.target.value));
                            }}
                          />
                          {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                      <Typography component={'div'} className='d-flex-a' />
                    </TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loader ? (
                      <Loader openLoader={loader} />
                    ) : searchParams.get('tabName') === 'priority' &&
                      priorityTableData?.length > 0 ? (
                      priorityTableData?.map((row: any, index: number) => (
                        <React.Fragment key={index}>
                          <TableRow
                            onClick={() =>
                              row.task_lock === false && navDocumentView(row, 'priority', index)
                            }
                            sx={{
                              '& > *': {
                                borderBottom: 'unset',
                                // backgroundColor: index % 2 ? '#fafffb' : '',
                                // backgroundColor: priority === 5 && '#aecef9',
                                cursor: row.task_lock ? 'not-allowed' : 'pointer',
                                backgroundColor: moment().isAfter(
                                  moment.unix(row?.created).add(12, 'hours'),
                                )
                                  ? index % 2
                                    ? 'rgb(250 237 223) !important'
                                    : 'rgb(252 243 234) !important'
                                  : index % 2
                                  ? '#fafffb'
                                  : '',
                              },
                            }}>
                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.email, 'Email/FTP File Name')
                              }>
                              <Typography component={'div'} className='d-flex-a'>
                                {' '}
                                {/* <Checkbox /> */}
                                <Typography
                                  sx={{
                                    fontFamily: 'Inter SemiBold !important',
                                    fontSize: '14px !important',
                                    lineHeight: '17px !important',
                                  }}>
                                  <RightMenu
                                    menuId={`${MENU_ID}`}
                                    copy={`copy${index + 1}`}
                                    data={priorityTableData}
                                  />
                                  {row?.email}
                                  {row.task_lock && (
                                    <span
                                      onClick={() => handleOnclickLock(row)}
                                      className='pointer'>
                                      <LockPersonIcon
                                        fontSize='small'
                                        sx={{ fill: 'red !important', marginLeft: '5px' }}
                                      />
                                    </span>
                                  )}
                                </Typography>{' '}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) => {
                                handleContextMenu(e, row, row?.source || '', 'Source');
                              }}>
                              {row?.source || '--'}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  row,
                                  row?.task_status.charAt(0).toUpperCase() +
                                    row?.task_status.slice(1) || '',
                                  'Status',
                                )
                              }>
                              {row?.task_status.charAt(0).toUpperCase() +
                                row?.task_status.slice(1) || '--'}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  row,
                                  row?.task_lock_by.charAt(0).toUpperCase() +
                                    row?.task_lock_by.slice(1) || '',
                                  'Assign',
                                )
                              }>
                              {row?.task_lock_by.charAt(0).toUpperCase() +
                                row?.task_lock_by.slice(1) || '--'}
                            </TableCell>

                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  row,
                                  moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--',
                                  'Created',
                                )
                              }>
                              <TextOnlyTooltip
                                arrow
                                title={moment.unix(row?.created).format('DD-MM-YYYY hh:mm a')}>
                                <div
                                  style={{
                                    width: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}>
                                  {moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'}
                                </div>
                              </TextOnlyTooltip>
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.client_code || '', 'Client Code')
                              }>
                              {row?.client_code || '--'}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(
                                  e,
                                  row,
                                  JSON.parse(row?.task_info)?.sac_code || '',
                                  'SCAC Code',
                                )
                              }>
                              {row?.scac || '--'}
                            </TableCell>
                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.description || '', 'Description')
                              }>
                              <TextOnlyTooltip arrow title={row?.description}>
                                <div
                                  style={{
                                    width: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}>
                                  {row?.description || '--'}
                                </div>
                              </TextOnlyTooltip>
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) => handleContextMenu(e, row, row.pages, 'Pages')}>
                              {row.pages || '--'}
                            </TableCell>
                            <TableCell
                              align='center'
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, row?.location || '', 'Location')
                              }>
                              {row?.location || '--'}
                            </TableCell>
                            <TableCell
                              onContextMenu={(e) =>
                                handleContextMenu(e, row, 'TEST INBOX', 'From')
                              }>
                              <TextOnlyTooltip arrow title={row?.from}>
                                <div
                                  style={{
                                    width: 150,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer',
                                  }}>
                                  {row?.from || '--'}
                                </div>
                              </TextOnlyTooltip>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    ) : (
                      !getMoreLoader && (
                        <React.Fragment>
                          <tr style={{ textAlign: 'center', height: '20vh' }}>
                            <td colSpan={12}>
                              <h2 style={{ color: 'grey' }}>No Data Found</h2>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </TableContainer>
          </TabPanel>
          <TabPanel value='6'></TabPanel>
          <TabPanel value='rescan'>
            {searchParams.get('tabName') === 'rescan' && (
              <TableContainer
                ref={topScroll}
                className='index-table'
                sx={{ height: 'calc(100vh - 165px)' }}
                id='scrollParent'>
                <InfiniteScroll
                  style={{ overflow: 'unset' }}
                  dataLength={Number(rescanTableData?.length)}
                  next={rescanGetMoreList}
                  hasMore={true}
                  scrollableTarget={'scrollParent'}
                  loader={
                    getMoreLoader && (
                      <div
                        style={{
                          backgroundColor: '#ffffff',
                          textAlign: 'center',
                          width: '100%',
                        }}>
                        <CircularProgress
                          size={30}
                          sx={{
                            color: '#002c69',
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        />
                      </div>
                    )
                  }
                  endMessage>
                  <Table aria-label='collapsible table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className='w-15'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Email/FTP File Name</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('email', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('email', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography> Source</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('source', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('source', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Status</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('task_status', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('task_status', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Assign</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('task_lock_by', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('task_lock_by', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Created Date</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('created', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('created', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <TextOnlyTooltip arrow title={'Client Code'}>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography>Client..</Typography>
                              <Typography className='p-relative'>
                                <span
                                  className='upArrow sortIcon pointer'
                                  onClick={() => sortTableData('client_code', true)}>
                                  ▲
                                </span>
                                <span
                                  className='downArrow sortIcon pointer'
                                  onClick={() => sortTableData('client_code', false)}>
                                  ▼
                                </span>
                              </Typography>
                            </Typography>
                          </TextOnlyTooltip>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Scac</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('scac', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('scac', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Description</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('description', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('description', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>Pages</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('pages', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('pages', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        <Tooltip title='Site Code/Client Location' placement='top'>
                          <TableCell className='w-8'>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography>Location</Typography>
                              <Typography className='p-relative'>
                                <span
                                  className='upArrow sortIcon pointer'
                                  onClick={() => sortTableData('location', true)}>
                                  ▲
                                </span>
                                <span
                                  className='downArrow sortIcon pointer'
                                  onClick={() => sortTableData('location', false)}>
                                  ▼
                                </span>
                              </Typography>
                            </Typography>
                          </TableCell>
                        </Tooltip>
                        <TableCell className='w-8'>
                          <Typography component={'div'} className='d-flex-a'>
                            <Typography>From</Typography>
                            <Typography className='p-relative'>
                              <span
                                className='upArrow sortIcon pointer'
                                onClick={() => sortTableData('from', true)}>
                                ▲
                              </span>
                              <span
                                className='downArrow sortIcon pointer'
                                onClick={() => sortTableData('from', false)}>
                                ▼
                              </span>
                            </Typography>
                          </Typography>
                        </TableCell>
                        {/* <TableCell className='w-15'>More</TableCell> */}
                      </TableRow>
                      <TableRow className='back-color'>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Email'
                              // variant='outlined'
                              placeholder='Email'
                              value={rescanFilterParams?.email}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  email: e.target.value,
                                }));
                                setRescanCookies('email', e.target.value);
                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              // label='Search Source'
                              // variant='outlined'
                              classes={{ root: classes.customTextField }}
                              placeholder='Source'
                              value={rescanFilterParams.source}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  source: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('source', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              // label='Search Status'
                              // variant='outlined'
                              classes={{ root: classes.customTextField }}
                              placeholder='Status'
                              value={rescanFilterParams.status}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  status: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('status', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Assign'
                              // variant='outlined'
                              placeholder='Assign'
                              value={rescanFilterParams.assign}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  assign: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('assign', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                value={
                                  rescanFilterParams?.created
                                    ? dayjs(moment.unix(rescanFilterParams?.created).format('L'))
                                    : null
                                }
                                disableFuture
                                format='DD/MM/YYYY'
                                onChange={(e) => {
                                  const date = moment(e.toDate()).format('L');
                                  setRescanFilterParams((prev: any) => ({
                                    ...prev,
                                    created: date ? moment(date).unix() : null,
                                  }));
                                  setRescanCookies('created', date ? moment(date).unix() : null);
                                }}
                              />
                            </LocalizationProvider>
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              placeholder='Client Code'
                              // label='Search Client'
                              // variant='outlined'
                              value={rescanFilterParams.client}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  client: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('client', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search SCAC'
                              // variant='outlined'
                              placeholder='Scac'
                              value={rescanFilterParams.scac}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  scac: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('scac', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Description'
                              // variant='outlined'
                              placeholder='Description'
                              value={rescanFilterParams.description}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  description: e.target.value,
                                }));
                                setRescanCookies('description', e.target.value);
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Pages'
                              // variant='outlined'
                              placeholder='Pages'
                              value={rescanFilterParams.pages}
                              // sx={{ width: '50px' }}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  pages: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('pages', searchRegexCheck(e.target.value));

                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search Location'
                              // variant='outlined'
                              placeholder='Location'
                              value={rescanFilterParams.location}
                              // sx={{ width: '50px' }}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  location: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('location', searchRegexCheck(e.target.value));

                                // searchFilterData();
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography component={'div'} className='d-flex-a'>
                            <TextField
                              classes={{ root: classes.customTextField }}
                              // label='Search From'
                              // variant='outlined'
                              placeholder='From'
                              value={rescanFilterParams.from}
                              sx={{ minwidth: '20px !important' }}
                              onChange={(e) => {
                                setRescanFilterParams((prev: any) => ({
                                  ...prev,
                                  from: searchRegexCheck(e.target.value),
                                }));
                                setRescanCookies('from', searchRegexCheck(e.target.value));
                              }}
                            />
                            {/* <img src={Filter} alt='icon' className='ml-15' /> */}
                          </Typography>
                        </TableCell>
                        {/* <TableCell>
                      <Typography component={'div'} className='d-flex-a' />
                    </TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {loader ? (
                        <Loader openLoader={loader} />
                      ) : searchParams.get('tabName') === 'rescan' &&
                        rescanTableData?.length > 0 ? (
                        rescanTableData?.map((row: any, index: number) => (
                          <React.Fragment key={index}>
                            <TableRow
                              className='pointer'
                              onClick={() =>
                                row.task_lock === false && navDocumentView(row, 'rescan', index)
                              }
                              sx={{
                                backgroundColor: index % 2 && '#fafffb',
                              }}>
                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.email, 'Email/FTP File Name')
                                }>
                                <Typography component={'div'} className='d-flex-a'>
                                  {' '}
                                  {/* <Checkbox /> */}
                                  <Typography
                                    sx={{
                                      fontFamily: 'Inter SemiBold !important',
                                      fontSize: '14px !important',
                                      lineHeight: '17px !important',
                                    }}>
                                    <RightMenu
                                      menuId={`${MENU_ID}`}
                                      copy={`copy${index + 1}`}
                                      data={pendingTableData}
                                    />
                                    {row?.email}
                                    {row.task_lock && (
                                      <span
                                        onClick={() => handleOnclickLock(row)}
                                        className='pointer'>
                                        <LockPersonIcon
                                          fontSize='small'
                                          sx={{ fill: 'red !important', marginLeft: '5px' }}
                                        />
                                      </span>
                                    )}
                                  </Typography>{' '}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) => {
                                  handleContextMenu(e, row, row?.source || '', 'Source');
                                }}>
                                {row?.source}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    row?.task_status.charAt(0).toUpperCase() +
                                      row?.task_status.slice(1) || '',
                                    'Status',
                                  )
                                }>
                                {row?.task_status.charAt(0).toUpperCase() +
                                  row?.task_status.slice(1) || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    row?.task_lock_by.charAt(0).toUpperCase() +
                                      row?.task_lock_by.slice(1) || '',
                                    'Assign',
                                  )
                                }>
                                {row?.task_lock_by.charAt(0).toUpperCase() +
                                  row?.task_lock_by.slice(1) || '--'}
                              </TableCell>

                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(
                                    e,
                                    row,
                                    moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '',
                                    'Created',
                                  )
                                }>
                                <TextOnlyTooltip
                                  arrow
                                  title={
                                    moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'
                                  }>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {moment.unix(row?.created).format('DD-MM-YYYY hh:mm a') || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.client_code || '', 'Client Code')
                                }>
                                {row?.client_code || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.scac || '', 'SCAC Code')
                                }>
                                {row?.scac || '--'}
                              </TableCell>
                              <TableCell
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.description || '', 'Description')
                                }>
                                <TextOnlyTooltip arrow title={row?.description}>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {row?.description || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row?.pages || '', 'Pages')
                                }>
                                {row?.pages || '--'}
                              </TableCell>
                              <TableCell
                                align='center'
                                onContextMenu={(e) =>
                                  handleContextMenu(e, row, row.location || '', 'Location')
                                }>
                                {row.location || '--'}
                              </TableCell>
                              <TableCell
                                onContextMenu={(e) => handleContextMenu(e, row, row?.from, 'From')}>
                                <TextOnlyTooltip arrow title={row?.from}>
                                  <div
                                    style={{
                                      width: 150,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    }}>
                                    {row?.from || '--'}
                                  </div>
                                </TextOnlyTooltip>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))
                      ) : (
                        !getMoreLoader && (
                          <React.Fragment>
                            <tr style={{ textAlign: 'center', height: '20vh' }}>
                              <td colSpan={12}>
                                <h2 style={{ color: 'grey' }}>No Data Found</h2>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      )}
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </TableContainer>
            )}
          </TabPanel>
        </TabContext>
        <div>
          <Modal
            open={modalOpen.isOpen}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                height: 200,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 5,
              }}>
              <Typography id='modal-modal-title' variant='h6' component='h2' className='d-flex-ja'>
                Are you sure you want to Unlock this Task?
              </Typography>
              <Typography
                sx={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: '40px' }}>
                <Typography>
                  <Button
                    variant='contained'
                    onClick={() => setModalOpen({ isOpen: false, data: '' })}>
                    Cancel
                  </Button>
                </Typography>
                <Typography>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() =>
                      searchParams.get('tabName') === 'pending'
                        ? taskLockUpdate(
                            modalOpen?.data?.signed_email_id,
                            modalOpen?.data?.created,
                            'unlock',
                          )
                        : priorityTaskLockUpdate(
                            modalOpen?.data?.signed_email_id,
                            modalOpen?.data?.created,
                            'unlock',
                          )
                    }>
                    Unlock
                  </Button>
                </Typography>
              </Typography>
            </Box>
          </Modal>
        </div>
      </Typography>
    </Typography>
  );
}
