import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import { AppAbout, AppContact, AppHome, AppPage, Other } from 'components/AppHome';
import Dashboard from 'pages/Dashboard';
// import DocumentView from 'pages/DocumentView';
import Analytics from 'pages/Analytics';
import { Login } from 'pages/Login';
import CompleteView from 'pages/CompleteVIew';
import ConFiguration from 'pages/Configuration';
import { IclarifyItem } from 'pages/IclarifyItem';
import Loader from 'components/Loader';
// import NotFound from 'pages/NotFound';
const DocumentView = lazy(() => import('../pages/DocumentView'));
const AppRoutes: React.FC = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to={'/dashboard'} />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/analytics' element={<Analytics />} />
      <Route
        path='/document-view/:taskStatus'
        element={
          <Suspense fallback={<Loader openLoader={true} />}>
            <DocumentView />
          </Suspense>
        }
      />
      <Route path='/complete-document-view/:taskStatus' element={<CompleteView />} />
      <Route path='/configuration' element={<ConFiguration />} />
      <Route path='*' element={<Navigate to={'/dashboard'} />} />
      <Route path='/iclarify' element={<IclarifyItem />} />
    </Routes>
  );
};

export const AuthRouts: React.FC = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to={'/login'} />} />
      <Route path='/login' element={<Login />} />
      <Route path='*' element={<Login />} />
      <Route path='/iclarify' element={<IclarifyItem />} />
    </Routes>
  );
};

export default React.memo(AppRoutes);
