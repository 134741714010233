import './style/style.scss';

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from 'store';
import { useNavigate } from 'react-router-dom';
import AppRoutes, { AuthRouts } from 'routes';
import _ from 'lodash';

function App() {
  const navigate = useNavigate();
  const checkToken = localStorage.getItem('accessToken');

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'clarify' && _.isEmpty(checkToken)) {
      navigate('/clarify');
    }
  }, []);

  return (
    <Provider store={store}>{!_.isEmpty(checkToken) ? <AppRoutes /> : <AuthRouts />}</Provider>
  );
}

export default App;
