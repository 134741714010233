import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
// import Loading from '../assets/images/Loading.gif';
import { ThreeDots } from 'react-loader-spinner';

const Loader = (props: any) => {
  const { openLoader } = props;

  return (
    <Typography>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          opacity: '0.5 !important',
        }}
        open={openLoader}>
        {/* <img src={Loading} style={{ maxHeight: '200px' }} /> */}
        <ThreeDots
          height='110'
          width='110'
          radius='9'
          color='blue'
          ariaLabel='three-dots-loading'
          wrapperStyle={{}}
          visible={true}
        />
      </Backdrop>
    </Typography>
  );
};

export default Loader;
